import { Moment } from "moment";
import { useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";

type CargosTransferenciasReporte = {
  fecha: string;
  key: React.Key;
  cod_producto_homologado: number;
  nombre_producto: string;
  cantidad: number;
  unidad_medida: number;
  precio_unitario: number;
  Costo_Contable: number;
  EnvioAPI: string;
  impuesto: string;
  sucursales: string;
};

const breadcrumbs = [
  { Label: "Reportes", Url: "/reporte" },
  { Label: "Ventas", Url: "/reportes/ventas" },
  { Label: "Resumen por producto" },
];
//const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Productividad" }];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useReporteVentaPorProductos() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [datos, setDatos] = useState<CargosTransferenciasReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<CargosTransferenciasReporte[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<CargosTransferenciasReporte>();
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [estadoBotones, setDataBoton] = useState<boolean>(false);

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
    return totalCostoFormateado;
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.nombre_producto.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const columns: TableColumnsType<CargosTransferenciasReporte> = [
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      className: "letraPequenaVisor2",
    },
    {
      title: "CodProduc",
      dataIndex: "cod_producto_homologado",
      key: "cod_producto_homologado",
      className: "letraPequenaVisor2",
    },
    {
      title: "Producto",
      dataIndex: "nombre_producto",
      key: "nombre_producto",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("nombre_producto"),
      width: "300px",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.cantidad - b.cantidad,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Unidad Medida",
      dataIndex: "unidad_medida",
      key: "unidad_medida",
      className: "letraPequenaVisor2",
    },
    {
      title: "Precio Unitario",
      dataIndex: "precio_unitario",
      key: "precio_unitario",
      align: "right",
      className: "letraPequenaVisor2",
      render: (_, record) => (
        <span style={{ color: record.precio_unitario === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.precio_unitario)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Costo",
      dataIndex: "Costo_Contable",
      key: "Costo_Contable",
      align: "right",
      className: "letraPequenaVisor2",
      render: (_, record) => (
        <span style={{ color: record.Costo_Contable === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.Costo_Contable)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "EnvioAPI",
      dataIndex: "EnvioAPI",
      key: "EnvioAPI",
      className: "letraPequenaVisor2",
    },
    {
      title: "Impuesto",
      dataIndex: "impuesto",
      key: "impuesto",
      className: "letraPequenaVisor2",
    },
    {
      title: "Sucursales",
      dataIndex: "sucursales",
      key: "sucursales",
      className: "letraPequenaVisor2",
    },
  ];

  const disabledDate = (current: moment.Moment) => {
    if (!fechaInicio) {
      return current && current >= moment().endOf("day"); // Mientras no haya fecha inicial, deshabilita fechas futuras
    }
    const minDate = fechaInicio.clone().startOf("day");
    const maxDate = fechaInicio.clone().add(6, "months").add(1, "days").endOf("day");
    const today = moment().startOf("day"); // Fecha actual (final del día)

    return current && (current < minDate || current > maxDate || current > today);
  };
  const getCargosTransferencias = async (fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDataBoton(true);
    try {
      const res = await fetch(
        url_interna + "api/reportes/ventas_reporte_por_producto",
        optionsPOST(
          JSON.stringify({
            fechaInicio: fechaInicio!.format("DD/MM/YYYY"),
            fechaFin: fechaFin!.format("DD/MM/YYYY"),
          }),
          {
            "api-token": "apitoken",
          }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: CargosTransferenciasReporte[] = respuesta.data;
        setDataEstado(true);
        setDataBoton(false);
        setDatos(
          respuestas.map(
            ({
              fecha,
              cod_producto_homologado,
              nombre_producto,
              cantidad,
              unidad_medida,
              precio_unitario,
              Costo_Contable,
              EnvioAPI,
              impuesto,
              sucursales,
              key,
            }) => ({
              fecha,
              cod_producto_homologado,
              nombre_producto,
              cantidad,
              unidad_medida,
              precio_unitario,
              Costo_Contable,
              EnvioAPI,
              impuesto,
              sucursales,
              key,
            })
          )
        );
      } else {
        setDataEstado(false);
        setDataBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataEstado(false);
      setDataBoton(false);
      return { msg: "no_conexion", status: false };
    }
  };

  const handleChangeButton = () => {
    try {
      if (fechaInicio && fechaFin) {
        getCargosTransferencias(fechaInicio, fechaFin);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickDownloadData = () => {
    const datosParaExportar = datos.map(({ key, ...resto }) => resto);
    exportToExcel(
      datosParaExportar,
      "Reporte ventas por producto" + fechaInicio!.format("DD/MM/YYYY") + " - " + fechaFin!.format("DD/MM/YYYY")
    );
  };

  return {
    columns,
    filteredData,
    handleChangeButton,
    estadoBotones,
    disabledDate,
    dataEstado,
    getCargosTransferencias,
    searchText,
    optionsPost,
    fechaInicio,
    fechaFin,
    setFechas,
    handleClickDownloadData,
    // calcularTotales,
  };
}
