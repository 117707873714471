import CuentasPorPagar from "pages/contentcontabilidad/CuentaPorPagar";
import CuentasPorCobrar from "pages/contentcontabilidad/CuentasPorCobrar";
import { EstadoIngreso } from "pages/contentcontabilidad/EstadoIngreso";
import { EstadoIngresoprueba } from "pages/contentcontabilidad/EstadoIngresoprueba";
import FacturasPorCuentas from "pages/contentcontabilidad/FacturasPorCuentas";
import { Principal } from "pages/contentprincipal/Principal";
import { RouteObject } from "react-router-dom";

export const rutasContabilidad: RouteObject[] = [
  {
    path: "/contabilidad",
    element: <Principal />,
  },
  {
    path: "/contabilidad/estado-perdida-ganancias",
    element: <EstadoIngreso />,
  },
  {
    path: "/contabilidad/estado-perdida-ganancias-prueba",
    element: <EstadoIngresoprueba />,
  },
  {
    path: "/contabilidad/cuentas-por-cobrar",
    element: <CuentasPorCobrar />,
  },
  {
    path: "/contabilidad/cuentas-por-pagar",
    element: <CuentasPorPagar />,
  },
  {
    path: "/contabilidad/facturas-cuentas",
    element: <FacturasPorCuentas />,
  },
];
