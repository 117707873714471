import { Form, Card, Select, Row, Col, Button, Table, DatePicker } from "antd";
import { ButtonExcel } from "components/otros/buttons";
import { useInventarioBodegas } from "hooks/inventarios/reportes/inventarioBodegas/useInventarioBodegas";
import { useVT } from "virtualizedtableforantd4";

const InventarioBodega = () => {
  const {
    form,
    dataSelectOptions,
    headTableReport,
    handleClickGenerar,
    dataTable,
    handleClickDownloadDataPrincipal,
    handleChangeSelect,
    typeSelect,
    bodegas,
    setDataTable,
    headTableReport_Bode,
    disabledFutureDate,
  } = useInventarioBodegas();

  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);

  return (
    <Card>
      <Form form={form}>
        <Row gutter={[10, 10]}>
          <Col xs={24} md={6}>
            <Form.Item label='Seleccione una fecha' name='fecha'>
              <DatePicker style={{ width: "100%" }} disabledDate={disabledFutureDate} />
              {/* <Select options={dataSelectOptions} onChange={handleChangeSelect}></Select> */}
            </Form.Item>
          </Col>
          <Col xs={24} md={typeSelect === 4 ? 8 : 11}>
            <Form.Item label='Seleccione una Categoría' name='cod_tipo'>
              <Select options={dataSelectOptions} onChange={handleChangeSelect}></Select>
            </Form.Item>
          </Col>
          {typeSelect === 4 && (
            <Col xs={24} md={8}>
              <Form.Item label='Seleccione una Farmacia' name='cod_farmacia'>
                <Select options={bodegas} onChange={() => setDataTable([])}></Select>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} md={2}>
            <Button type='primary' style={{ width: "100%" }} onClick={handleClickGenerar} /*loading={loader}*/>
              Generar
            </Button>
          </Col>
          {dataTable.length > 0 && (
            <Col xs={24}>
              <Table
                components={vt}
                columns={typeSelect === 6 ? headTableReport_Bode : headTableReport}
                dataSource={dataTable}
                scroll={{ x: 900, y: 420 }}
                //scroll={{ x: true }}
                size='small'
                pagination={false}
                bordered
              ></Table>
            </Col>
          )}
        </Row>
        <Row gutter={[5, 5]}>
          <Col xs={18}></Col>
          <Col xs={6}>
            {dataTable.length > 0 && <ButtonExcel buttonProps={{ onClick: handleClickDownloadDataPrincipal }}></ButtonExcel>}
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default InventarioBodega;
