import { Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import { optionsGET, url_interna } from "config/constants";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useCallback, useContext, useEffect, useState } from "react";
import { useExport } from "hooks/otros/useExport";
import moment from "moment";
import { useSearchTable } from "hooks/otros/useSearchTable";
import { swalError } from "config/methods";

const breadcrumbs = [{ Label: "Promociones MF Reporte" }];

interface PropTablePromociones {
  key: number;
  id: number;
  Descripcion: string;
  Cod_Barra: string;
  Fraccion: string;
  PM: string;
  Laboratorio: string;
  Proveedor: string;
  Tipo: string;
  Cantidad: number;
  Bonificacion: number;
  // Cod_Producto_Bonificacion: string;
  Producto_Bonificacion: string;
  Fecha_Inicio: string;
  Fecha_Fin: string;
  Descuento: number;
  PVP: number;
  "Valor%": number;
  Descuento_Cadena: number;
  venta_minima: number;
  Producto_Fraccionado: string;
  Es_Promocion_Domingo: string;
  Es_Promocion_Lunes: string;
  Es_Promocion_Martes: string;
  Es_Promocion_Miercoles: string;
  Es_Promocion_Jueves: string;
  Es_Promocion_Viernes: string;
  Es_Promocion_Sabado: string;
}

export interface OptionType {
  value: number;
  label: string;
}

export const useReportePromocionesMF = () => {
  useBreadCrumbs(breadcrumbs);
  const [form] = Form.useForm();
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [options] = useState<OptionType[]>([]);
  const [dateTable, setDateTable] = useState<PropTablePromociones[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { exportToExcel } = useExport();
  const { getColumnSearchProps } = useSearchTable<PropTablePromociones>();

  useEffect(() => {
    setOpenKeys(["Productos", "Productos"]);
    setSelectedKeys(["Productos/Promociones MF Reporte"]);
  }, [setOpenKeys, setSelectedKeys]);

  const headTable: ColumnsType<PropTablePromociones> = [
    {
      title: "Cod_Barra",
      dataIndex: "Cod_Barra",
      key: "Cod_Barra",
      className: "customTbl",
      width: "88px",
      fixed: "left",
      ...getColumnSearchProps("Cod_Barra"),
    },
    {
      title: "Id",
      dataIndex: "key",
      key: "key",
      className: "customTbl",
      width: "45px",
      fixed: "left",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Descripcion",
      dataIndex: "Descripcion",
      key: "Descripcion",
      className: "customTbl",
      width: "88px",
      fixed: "left",
      ...getColumnSearchProps("Descripcion"),
    },
    {
      title: "Uni. Medida",
      dataIndex: "Fraccion",
      key: "Fraccion",
      className: "customTbl",
      width: "78px",
      ...getColumnSearchProps("Fraccion"),
    },
    {
      title: "PM",
      dataIndex: "PM",
      key: "PM",
      className: "customTbl",
      width: "68px",
      ...getColumnSearchProps("PM"),
    },
    {
      title: "Laboratorio",
      dataIndex: "Laboratorio",
      key: "Laboratorio",
      className: "customTbl",
      //  width: "8%",
      width: "98px",
      ...getColumnSearchProps("Laboratorio"),
    },
    {
      title: "Proveedor",
      dataIndex: "Proveedor",
      key: "Proveedor",
      className: "customTbl",
      //width: "10%",
      width: "98px",

      ...getColumnSearchProps("Proveedor"),
    },
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "Tipo",
      className: "customTbl",
      // width: "6%",
      width: "68px",

      ...getColumnSearchProps("Tipo"),
    },
    {
      title: "Cantidad",
      dataIndex: "Cantidad",
      key: "Cantidad",
      className: "customTbl",
      align: "right",
      width: "68px",

      sorter: (a, b) => a.Cantidad - b.Cantidad,
    },
    {
      title: "Bonificacion",
      dataIndex: "Bonificacion",
      key: "Bonificacion",
      className: "customTbl",
      width: "78px",
      align: "right",
      sorter: (a, b) => a.Cantidad - b.Cantidad,
    },
    {
      title: "Producto Bonificacion",
      dataIndex: "Producto_Bonificacion",
      key: "Producto_Bonificacion",
      className: "customTbl",
      width: "98px",
      ...getColumnSearchProps("Producto_Bonificacion"),
    },
    {
      title: "Fecha Inicio",
      dataIndex: "Fecha_Inicio",
      key: "Fecha_Inicio",
      className: "customTbl",
      width: "68px",
    },
    {
      title: "Fecha Fin",
      dataIndex: "Fecha_Fin",
      key: "Fecha_Fin",
      className: "customTbl",
      width: "68px",
    },
    {
      title: "Descuento",
      dataIndex: "Descuento",
      key: "Descuento",
      className: "customTbl",
      align: "right",
      width: "68px",
      sorter: (a, b) => a.Cantidad - b.Cantidad,
    },
    {
      title: "PVP",
      dataIndex: "PVP",
      key: "PVP",
      className: "customTbl",
      align: "right",
      width: "68px",
      sorter: (a, b) => a.PVP - b.PVP,
    },
    {
      title: "Valor%",
      dataIndex: "Valor%",
      key: "Valor%",
      className: "customTbl",
      align: "right",
      width: "68px",
      sorter: (a, b) => a["Valor%"] - b["Valor%"],
    },
    {
      title: "Desc. Cadena",
      dataIndex: "Descuento_Cadena",
      key: "Descuento_Cadena",
      className: "customTbl",
      align: "right",
      width: "88px",
      sorter: (a, b) => a.Cantidad - b.Cantidad,
    },
    {
      title: "Venta Minima",
      dataIndex: "venta_minima",
      key: "venta_minima",
      className: "customTbl",
      // align: "right",
      width: "68px",
      sorter: (a, b) => a.Cantidad - b.Cantidad,
    },
    {
      title: "Produc Fraccionado",
      dataIndex: "Producto_Fraccionado",
      key: "Producto_Fraccionado",
      className: "customTbl",
      align: "right",
      width: "78px",
      sorter: (a, b) => a.Cantidad - b.Cantidad,
    },
    {
      title: "Promoción Domingo",
      dataIndex: "Es_Promocion_Domingo",
      key: "Es_Promocion_Domingo",
      className: "customTbl",
      width: "68px",
    },
    {
      title: "Promoción Lunes",
      dataIndex: "Es_Promocion_Lunes",
      key: "Es_Promocion_Lunes",
      className: "customTbl",
      width: "68px",
    },
    {
      title: "Promoción Martes",
      dataIndex: "Es_Promocion_Martes",
      key: "Es_Promocion_Martes",
      className: "customTbl",
      width: "68px",
    },
    {
      title: "Promoción Miércoles",
      dataIndex: "Es_Promocion_Miercoles",
      key: "Es_Promocion_Miercoles",
      className: "customTbl",
      width: "68px",
    },
    {
      title: "Promoción Jueves",
      dataIndex: "Es_Promocion_Jueves",
      key: "Es_Promocion_Jueves",
      className: "customTbl",
      width: "68px",
    },
    {
      title: "Promoción Viernes",
      dataIndex: "Es_Promocion_Viernes",
      key: "Es_Promocion_Viernes",
      className: "customTbl",
      width: "68px",
    },
    {
      title: "Promoción Sábado",
      dataIndex: "Es_Promocion_Sabado",
      key: "Es_Promocion_Sabado",
      className: "customTbl",
      width: "68px",
    },
  ];

  const handleClickDownload = () => {
    if (!dateTable) return;
    exportToExcel(dateTable, ` Reporte promociones_MF${moment().format("YYYY-MM-DD_HH-mm-ss")}`);
  };

  const handleDataTable = useCallback(async () => {
    try {
      setLoader(true);
      const respuesta = await fetch(url_interna + "api/productos/listar-promociones", optionsGET).then((data) =>
        data.json()
      );

      if (!respuesta.msg) {
        setLoader(false);
        return swalError({ text: "Ocurrio un error!" });
      }

      setDateTable(respuesta.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      swalError({ text: "Ocurrio un error!" });
    }
  }, []);

  useEffect(() => {
    handleDataTable();
  }, [handleDataTable]);

  return {
    headTable,
    options,
    dateTable,
    form,
    handleClickDownload,
    loader,
  };
};
