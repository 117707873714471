import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../config/constants";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { optionsGet, swalError } from "config/methods";
import { useExport } from "hooks/otros/useExport";
type InventarioReporte = {
  key: React.Key;
  Fecha: string;
  Cod_Bodega:number;
  Bodega:string;
  Usuario:string;
  Cod_Producto: number;
  Cod_MIA: string;
  Descripcion: string;
  cantidad: number;
  unidad_medida: number;
  CostoFraccion: number;
  EnvioAPI: string;
  impuesto: string;
};

export interface DatosPeriodo {
  label: string;
  value: number;
}
const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Movimientos" }];

const openKeys = [breadcrumbs[0].Label];
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`;
//const openKeys = [breadcrumbs[0].Label];
export function useInventarioMovimientos() {
  useLayout(breadcrumbs, openKeys, selectKeySider);
  // useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);

  const [datos, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);
  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [estadoBotonNuevo, setEstadoBotonNuevo] = useState<boolean>(false);

  const [tipoMovimiento, setTipoMovimiento] = useState<number>();
  const { exportToExcel } = useExport();
  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [datosPeriodos, setDatosPeriodos] = useState<DatosPeriodo[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();

  const { isError } = useVerifyResponse();

  const getDatosCuentas = async (tipoMovimiento: number, fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDataBoton(true);
    setEstadoBotonNuevo(false);
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener-movimientos",
        optionsPOST(
          JSON.stringify({
            tipoMovimiento: tipoMovimiento,
            fechaInicio: fechaInicio!.format("DD/MM/YYYY"),
            fechaFin: fechaFin!.format("DD/MM/YYYY"),
          }),
          {
            "api-token": "apitoken",
          }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: InventarioReporte[] = respuesta.data;
        setDataBoton(false);
        setDataEstado(true);
        setDatos(
          respuestas.map(
            ({
              key,
              Fecha,
              Cod_Bodega,
              Bodega,
              Usuario,
              Cod_Producto,
              Cod_MIA,
              Descripcion,
              cantidad,
              unidad_medida,
              CostoFraccion,
              EnvioAPI,
              impuesto,
            }) => ({
              key,
              Fecha,
              Cod_Bodega,
              Bodega,
              Usuario,
              Cod_Producto,
              Cod_MIA,
              Descripcion,
              cantidad,
              unidad_medida,
              CostoFraccion,
              EnvioAPI,
              impuesto,
            })
          )
        );
      } else {
        setDatos([]);
        setDataBoton(false);
        setDataEstado(true);
        swalError({ text: "No se pudieron obtener registros en la fecha seleccionada" });
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  };
  const disabledDate = (current: moment.Moment) => {
    const currentYear = moment().year(); // Año actual

    if (!fechaInicio) {
      // Si no hay fechaInicio, deshabilitar fechas futuras y años distintos al actual
      return current && (current.year() !== currentYear || current > moment().endOf("day"));
    }

    const minDate = fechaInicio.clone().startOf("day");
    const maxDate = fechaInicio.clone().add(6, "months").add(1, "days").endOf("day");
    const today = moment().endOf("day"); // Fecha actual (final del día)

    // Deshabilitar fechas fuera del rango permitido o años distintos al actual
    return current && (current.year() !== currentYear || current < minDate || current > maxDate || current > today);
  };

  const getDatosIniciales = useCallback(async () => {
    setDatosPeriodos([]);
    try {
      const res = await fetch(url_interna + "api/inventarios/obtener_tipo_movimientos_inventario", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const respuestas: DatosPeriodo[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatosPeriodos(
            respuestas.map(({ label, value }) => ({
              label,
              value,
            }))
          );
        } else {
          setDatosPeriodos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);
  // Función para determinar el estilo basado en el estado
  const getOptionColor = (estado: number) => {
    switch (estado) {
      case 1:
        return "#ff4d4f"; // Rojo para estado 1
      case 2:
        return "#52c41a"; // Verde para estado 2
      default:
        return "#d9d9d9"; // Gris para otros estados
    }
  };

  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);

  const handleClickDownloadData = () => {
    if (filteredData.length > 0) {
      let datosParaExportar = [];

      datosParaExportar = filteredData.map(({ ...resto }) => resto);

      datosParaExportar = datosParaExportar.map(({ key, Cod_Producto, ...resto }) => resto);
      exportToExcel(
        datosParaExportar,
        "Reporte de cuenta " +
          fechaInicio!.format("DD/MM/YYYY") +
          " " +
          fechaFin!.format("DD/MM/YYYY") +
          " " +
          tipoMovimiento
      );
    }
  };

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
    return totalCostoFormateado;
  };
  const handleChangeButton = () => {
    try {
      if (tipoMovimiento && fechaInicio && fechaFin) {
        getDatosCuentas(tipoMovimiento, fechaInicio, fechaFin);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Descripcion?.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);
  // UseEffect para actualizar los datos al cambiar el mes
  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Fecha",
      dataIndex: "Fecha",
      key: "Fecha",
      className: "letraPequenaVisor2",
      width: "85px",
    },
    {
      title: "Cod",
      dataIndex: "Cod_Bodega",
      key: "Cod_Bodega",
      className: "letraPequenaVisor2",
    },
    {
      title: "Bodega",
      dataIndex: "Bodega",
      key: "Bodega",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("Bodega"),
      width: "150px",
    },
    {
      title: "Usuario",
      dataIndex: "Usuario",
      key: "Usuario",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("Usuario"),
      width: "150px",
    },
    {
      title: "Cod_MIA",
      dataIndex: "Cod_MIA",
      key: "Cod_MIA",
      className: "letraPequenaVisor2",
    },
    {
      title: "Producto",
      dataIndex: "Descripcion",
      key: "Descripcion",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("Descripcion"),
      width: "300px",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.cantidad - b.cantidad,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Precio Unitario",
      dataIndex: "CostoFraccion",
      key: "CostoFraccion",
      align: "right",
      className: "letraPequenaVisor2",
      render: (_, record) => (
        <span style={{ color: record.CostoFraccion === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.CostoFraccion)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "EnvioAPI",
      dataIndex: "EnvioAPI",
      key: "EnvioAPI",
      className: "letraPequenaVisor2",
    },
    {
      title: "Impuesto",
      dataIndex: "impuesto",
      key: "impuesto",
      className: "letraPequenaVisor2",
    },
  ];

  return {
    columns,
    fechaInicio,
    fechaFin,
    setFechas,
    filteredData,
    datosPeriodos,
    setTipoMovimiento,
    tipoMovimiento,
    estadoBotones,
    dataEstado,
    handleClickDownloadData,
    disabledDate,
    estadoBotonNuevo,
    getOptionColor,
    handleChangeButton,
  };
}
