import { useEffect, useContext, useCallback, useState, useRef } from "react";
import { LayoutContext } from "../../../context/LayoutContext";
import { url } from "../../../config/constants";
import moment from "moment";
import "moment/locale/es";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLocalStorage } from "../../otros/useLocalStorage";
import { Form, RadioChangeEvent } from "antd";
import { UserContext } from "context/UserContext";
import { swalError } from "config/methods";
import { LatLngExpression } from "leaflet";

/*Ultimos */

import { DatosPersonales } from "../../../components/rrhh/componentsActualizacion/DatosPersonales";
import { FormularioDestrezas } from "../../../components/rrhh/componentsActualizacion/FormularioDestrezas";
import { CroquisMap } from "../../../components/rrhh/componentsActualizacion/CroquisMap";
import { Referencias } from "../../../components/rrhh/componentsActualizacion/Referencias";
import { Archivos } from "../../../components/rrhh/componentsActualizacion/Archivos";
import { FormInstance } from "antd/es/form";
import { VerArchivos } from "components/rrhh/componentsActualizacion/VerArchivos";
import { VerArchivosEdit } from "components/rrhh/componentsActualizacion/VerArchivosEdit";
import { PropsFormu } from "pages/contentrecursoshumanos/contentactualizacion/RevisarInformacion";

export interface PropArchivos {
  archivos: PropsLlaves[] | undefined;
  form: FormInstance<any>;
  setEstado2: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface PropsLlaves {
  key: number;
  llave: string;
  estado: number;
}

function getNameAndLastName(str: string) {
  let tokens = str.split(" ");

  let names: any = [];

  const special_tokens = ["da", "de", "del", "la", "las", "los", "mac", "mc", "van", "von", "y", "i", "san", "santa"];

  let prev = "";

  tokens.forEach((token) => {
    let _token = token.toLowerCase();
    if (special_tokens.includes(_token)) {
      prev = `${prev}${_token.charAt(0).toUpperCase() + _token.slice(1)} `;
    } else {
      names.push(`${prev}${_token.charAt(0).toUpperCase() + _token.slice(1)}`);
      prev = "";
    }
  });

  let num_nombres = names.length;

  let nombres = "",
    apellidos = "";

  switch (num_nombres) {
    case 0:
      nombres = "";
      break;
    case 1:
      nombres = names[0];
      break;
    case 2:
      nombres = names[0];
      apellidos = names[1];
      break;
    case 3:
      nombres = names[0];
      apellidos = `${names[1]} ${names[2]}`;
      break;
    case 4:
      nombres = `${names[0]} ${names[1]}`;
      apellidos = `${names[2]} ${names[3]}`;
      break;
    default:
      nombres = `${names[0]} ${names[1]}`;
      names.splice(0, 2);
      apellidos = names.join(" ");
      break;
  }
  return { nombres, apellidos };
}

export interface Props {
  // handleChangeCedula: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setValidaOperacion: React.Dispatch<React.SetStateAction<boolean>>;
  validaOperacion: boolean;
  // setValidaConyugue: React.Dispatch<React.SetStateAction<boolean>>;
  validaConyugue: boolean;
  activeKey2: string;
  setActiveKey2: React.Dispatch<React.SetStateAction<string>>;
  form: FormInstance<any>;
  setFileSelected: React.Dispatch<React.SetStateAction<boolean>>;
  fileSelected: boolean;
  hidden: boolean;
  setHidden: React.Dispatch<React.SetStateAction<boolean>>;
  hidden2: boolean;
  setHidden2: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeIngreso: (value: RadioChangeEvent) => void;
  handleChangeParentezco: (value: RadioChangeEvent) => void;
  handleChangeHijos: (value: RadioChangeEvent) => void;
  changeCivil: (value: RadioChangeEvent) => void;
  validaEstudio: boolean;
  hijos: boolean;
  handleChange2: (value: RadioChangeEvent) => void;
}

export interface Files {
  cedula_conyugue?: FileList[];
  cedula_votacion?: FileList[];
  cedulas_hijos?: FileList[];
  foto_carnet?: FileList[];
  hijos_discapacidad?: FileList[];
  licencia?: FileList[];
}

export interface FilesObject {
  file: File;
  fileList: FileList[];
  length: number;
}

export interface File {
  uid: string;
}

export interface FileList {
  archivo: string;
  uid: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  percent: number;
  originFileObj: File;
}
const breadCrumbs = [
  {
    Label: "ACTUALIZACIÓN DE INFORMACIÓN DEL EMPLEADO , SOCIEDAD CIVIL DE HECHO DENOMINADO GRUPO USCOCOVICH",
    Url: "rrhh/formulario",
  },
];

export const useFormularioActualizacion = (
  flag?: number,
  coordenadas?: any,
  archivos?: any,
  handleOk?: any,
  row?: any,
  getEnviadas?: (value: number) => void,
  valuePeriodo?: number,
  enviadas?: PropsFormu[],
  setEnviadas?: (data: PropsFormu[]) => void
) => {
  // export function useFormularioActualizacion(flag?: number) {
  const [update, setUpdate] = useState<number>();
  const ubicacion = useRef<LatLngExpression | null>(null);

  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const { optionsGet, optionsPost, getItem } = useLocalStorage();
  const [validaEstudio, setValidaEstudio] = useState<boolean>(false);
  const [fileSelected, setFileSelected] = useState<boolean>(false);

  const [validaOperacion, setValidaOperacion] = useState<boolean>(false);
  const [validaConyugue, setValidaConyugue] = useState<boolean>(false);

  const [activeKey, setActiveKey] = useState<string>("1");
  const [activeKey2, setActiveKey2] = useState<string>("1");

  const [form] = Form.useForm();

  const { userData } = useContext(UserContext);

  const [estado, setEstado] = useState<boolean>(false);
  const [archivosEdit, setArchivosEdit] = useState<PropsLlaves[]>([]);
  /*Cambios */

  const [hidden, setHidden] = useState<boolean>(true);
  const [hidden2, setHidden2] = useState<boolean>(true);
  const [hijos, setHijos] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderGeneral, setLoaderGeneral] = useState(false);

  useBreadCrumbs(breadCrumbs);

  useEffect(() => {
    (() => {
      if (flag === 0) {
        setOpenKeys(["Recursos Humanos/Actualizar Información", "Recursos Humanos"]);
        setSelectedKeys(["Actualizar Información/Llenar Formulario"]);
        return;
      } else if (flag === 1) {
        setOpenKeys(["Recursos Humanos/Actualizar Información", "Recursos Humanos"]);
        setSelectedKeys(["Actualizar Información/Revisar Información"]);
      }
    })();
  }, [setOpenKeys, setSelectedKeys, flag]);

  const handleChange2 = ({ target: { value } }: RadioChangeEvent) => {
    if (value === true) {
      setValidaEstudio(true);
    } else {
      setValidaEstudio(false);
    }
  };

  const handleChangeIngreso = ({ target: { value } }: RadioChangeEvent) => {
    form.setFieldsValue({ cual_ingreso: "", monto_ingreso: "" });
    value === true ? setHidden(false) : setHidden(true);
  };

  const handleChangeParentezco = ({ target: { value } }: RadioChangeEvent) => {
    form.setFieldsValue({ nombres_relacion_parentesco: "", tipo_parentesco: "" });
    value === true ? setHidden2(false) : setHidden2(true);
  };

  const handleChangeHijos = ({ target: { value } }: RadioChangeEvent) => {
    form.setFieldsValue({ cantidad_hijos: "" });
    setHijos(value);
  };

  const changeCivil = ({ target: { value } }: RadioChangeEvent) => {
    if (value === 1 || value === 3 || value === 4) {
      setValidaConyugue(false);
    } else {
      setValidaConyugue(true);
    }
  };

  const items = {
    items: [
      {
        label: "INFORMACIÓN PERSONAL",
        key: "1",
        tabKey: "1",
        forceRender: true,
        children: (
          <DatosPersonales
            validaOperacion={validaOperacion}
            setValidaOperacion={setValidaOperacion}
            validaConyugue={validaConyugue}
            changeCivil={changeCivil}
            activeKey2={activeKey2}
            setActiveKey2={setActiveKey2}
            form={form}
            setFileSelected={setFileSelected}
            fileSelected={fileSelected}
            hidden={hidden}
            setHidden={setHidden}
            hidden2={hidden2}
            setHidden2={setHidden2}
            handleChangeIngreso={handleChangeIngreso}
            handleChangeParentezco={handleChangeParentezco}
            handleChangeHijos={handleChangeHijos}
            hijos={hijos}
            validaEstudio={validaEstudio}
            handleChange2={handleChange2}
          />
        ),
      },
      {
        label: "DESTREZAS Y APTITUDES",
        key: "2",
        children: <FormularioDestrezas form={form} />,
        tabKey: "2",
        forceRender: true,
      },
      {
        label: "OTROS DATOS",
        tabKey: "3",
        key: "3",
        children: <Referencias />,
        forceRender: true,
      },
      {
        label: "CROQUIS DEL DOMICILIO",
        key: "4",
        children: <CroquisMap ubicacion={ubicacion} flag={flag} coordenadas={coordenadas} />,
        tabKey: "4",
      },
      {
        label: "IMPORTANTE",
        key: "5",
        tabKey: "5",
        children:
          flag === 0 ? (
            update !== 2 ? (
              <Archivos validaConyugue={validaConyugue} setFileSelected={setFileSelected} />
            ) : (
              <VerArchivosEdit form={form} archivos={archivosEdit} setEstado2={setEstado} />
            )
          ) : (
            <VerArchivos
              archivos={archivos}
              form={form}
              handleOk={handleOk}
              getEnviadas={getEnviadas}
              valuePeriodo={valuePeriodo}
              enviadas={enviadas}
              setEnviadas={setEnviadas}
            />
          ),
        forceRender: true,
      },
    ],
  };

  const setInfoLastPeriodo = (record: any) => {
    if (record.estado_civil === 2 || record.estado_civil === 5) setValidaConyugue(true);
    if (record.es_cirugias) setValidaOperacion(record.es_cirugias);
    if (record.es_estudias) setValidaEstudio(record.es_estudias);
    if (record.es_hijos) setHijos(record.es_hijos);

    record.es_ingreso_adicional && setHidden(!record.es_ingreso_adicional);
    record.es_relacion_parentesco && setHidden2(!record.es_relacion_parentesco);

    record.fecha_actualizacion && (record.fecha_actualizacion = moment(record.fecha_actualizacion));
    record.fecha && (record.fecha = moment(record.fecha));
    record.fecha_nacimiento && (record.fecha_nacimiento = moment(record.fecha_nacimiento));
    record.fecha_examen && (record.fecha_examen = moment(record.fecha_examen));

    let direcciones = typeof record.direccion === "string" ? JSON.parse(record.direccion) : record.direccion;
    let emergencia = typeof record.emergencia === "string" ? JSON.parse(record.emergencia) : record.emergencia;
    let madre = typeof record.madre === "string" ? JSON.parse(record.madre) : record.madre;
    let padre = typeof record.padre === "string" ? JSON.parse(record.padre) : record.padre;
    let parientes = typeof record.parientes === "string" ? JSON.parse(record.parientes) : record.parientes;

    record.padre && (record.padre = padre);
    record.madre && (record.madre = madre);
    record.direccion && (record.direccion = direcciones);
    record.emergencia && (record.emergencia = emergencia);
    record.parientes && (record.parientes = parientes);

    record.tipo_sangre = record.cod_tipo_sangre;

    if (record.primaria) {
      let primaria = typeof record.primaria === "string" ? JSON.parse(record.primaria) : record.primaria;
      if (Object.keys(primaria).length > 0) {
        primaria.inicio = moment(primaria.inicio);
        primaria.fin = moment(primaria.fin);
        record.primaria = primaria;
      }
    }

    if (record.secundaria) {
      let secundaria = typeof record.secundaria === "string" ? JSON.parse(record.secundaria) : record.secundaria;
      if (Object.keys(secundaria).length > 0) {
        secundaria.inicio = moment(secundaria.inicio);
        secundaria.fin = moment(secundaria.fin);
        record.secundaria = secundaria;
      }
    }

    if (record.tercer) {
      let tercer = typeof record.tercer === "string" ? JSON.parse(record.tercer) : record.tercer;
      if (Object.keys(tercer).length > 0) {
        tercer.inicio = moment(tercer.inicio);
        tercer.fin = moment(tercer.fin);
        record.tercer = tercer;
      }
    }

    if (record.cuarto) {
      let cuarto = typeof record.cuarto === "string" ? JSON.parse(record.cuarto) : record.cuarto;
      if (Object.keys(cuarto).length > 0) {
        cuarto.inicio = moment(cuarto.inicio);
        cuarto.fin = moment(cuarto.fin);
        record.cuarto = cuarto;
      }
    }
    if (record.residentes && record.residentes.length > 0) {
      record.residentes.forEach((el: any) => {
        if (el.fecha_nacimiento_residente) {
          el.fecha_nacimiento_residente = moment(el.fecha_nacimiento_residente);
        }
      });
    }

    if (record.cursos && record.cursos.length > 0) {
      record.cursos.forEach((el: any) => {
        if (el.fecha_inicio) {
          el.fecha_inicio = moment(el.fecha_inicio);
        }
        if (el.fecha_fin) {
          el.fecha_fin = moment(el.fecha_fin);
        }
      });
    }

    if (record.hijos && record.hijos.length > 0) {
      record.hijos.forEach((el: any) => {
        if (el.fecha_nacimiento_hijo) {
          el.fecha_nacimiento_hijo = moment(el.fecha_nacimiento_hijo);
        }
      });
    }
    return record;
  };

  const getEstados = useCallback(
    async (cod_usuario: number | undefined) => {
      try {
        const respuesta = await fetch(url + "rrhh/estado", optionsPost({ cod_usuario })).then((data) => data.json());

        if (respuesta.msg) {
          const { data } = respuesta;
          return { data, status: true };
        } else {
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [optionsPost]
  );

  const getEstadosIndividual = useCallback(
    async (cod_usuario: number | undefined) => {
      try {
        const respuesta = await fetch(url + "apifsg-pr/rrhh/estado-individual", optionsPost({ cod_usuario })).then((data) =>
          data.json()
        );

        if (respuesta.msg === "ok") {
          const { data } = respuesta;
          return { data, status: true };
        } else {
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    },
    [optionsPost]
  );

  const getInformacion = useCallback(
    async (cod_usuario: number | undefined) => {
      try {
        const respuesta = await fetch(url + "rrhh/data-inicial", optionsPost({ cod_usuario })).then((data) => data.json());
        if (respuesta.msg) {
          const { data } = respuesta;

          return { data, status: true };
        } else {
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        // console.log(e)
        return { msg: "no_conexion", status: false };
      }
    },
    [optionsPost]
  );

  const getData = useCallback(async () => {
    try {
      setLoaderGeneral(true);
      if (userData && flag === 0) {
        let { cedula, nombre, id, fecha_ingreso } = userData;
        let a = getNameAndLastName(nombre);

        form.setFieldsValue({
          nombres: a.apellidos,
          apellidos: a.nombres,
          cedula,
          fecha: moment(fecha_ingreso),
        });

        let res = await getEstadosIndividual(id);
        setEstado(res.data[0].estado_actualizacion);

        let res2 = await getEstados(id);

        setUpdate(res2.data[0].cod_estado);

        if (res2.data[0].cod_estado === 0) {
          let data = localStorage.getItem("G" + userData.id) || null;

          if (!data) {
            const respuesta = await fetch(url + "rrhh/informacion-enviada-id", optionsPost({ id })).then((data) =>
              data.json()
            );

            if (respuesta && respuesta.data.length > 0) {
              form.setFieldsValue(setInfoLastPeriodo(respuesta.data[0]));
              localStorage.setItem("G" + userData.id, JSON.stringify(setInfoLastPeriodo(respuesta.data[0])));
              setLoaderGeneral(false);
            }
          } else {
            form.setFieldsValue(setInfoLastPeriodo(JSON.parse(data)));
            setLoaderGeneral(false);
          }
        } else if (res2.data[0].cod_estado === 2) {
          let dataCorrecion = await getInformacion(id);
          setLoaderGeneral(false);
          setArchivosEdit(dataCorrecion.data[0].archivos);
          dataCorrecion.status && form.setFieldsValue(setInfoLastPeriodo(dataCorrecion.data[0]));
        }
      } else if (row && flag === 1) {
        form.setFieldsValue(setInfoLastPeriodo(row));
        setLoaderGeneral(false);
      }
    } catch (error) {
      swalError({ text: "Ocurrio un error!" });
      setLoaderGeneral(false);
    }
    setLoaderGeneral(false);
  }, [optionsPost, flag, form, getEstados, getEstadosIndividual, getInformacion, row, userData]);

  useEffect(() => {
    getData();
  }, [getData, estado]);

  const handleChange = (record: any, allValue: any) => {
    if (flag === 0) {
      if (
        Object.keys(record)[0] === "foto_carnet" ||
        Object.keys(record)[0] === "cedula_votacion" ||
        Object.keys(record)[0] === "licencia" ||
        Object.keys(record)[0] === "cedulas_hijos" ||
        Object.keys(record)[0] === "cedula_conyugue" ||
        Object.keys(record)[0] === "hijos_discapacidad"
      )
        return;
      let data = JSON.parse(localStorage.getItem("G" + userData?.id) || "{}");
      const clave = Object.keys(record)[0];
      if (Array.isArray(allValue[clave])) {
        let a = allValue[clave].filter((element: any) => element !== undefined);
        allValue[clave] = a;
      }

      let data2 = { ...data, [clave]: allValue[clave] };
      localStorage.setItem("G" + userData?.id, JSON.stringify(data2));
    }
  };

  const helperLlaves = (files: FileList[]): Promise<{ ok: boolean; llaves?: string[]; msg?: string }> => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = files;
        let llaves: string[] = [];

        const requests = data.map(async (el: any) => {
          const body = new FormData();
          body.append("uploadfile", el.originFileObj);
          body.append("archivo", el.archivo);

          const requestSettings = {
            body,
            method: "POST",
            headers: {
              Accept: "application/json",
              "access-token": getItem("token") ?? "",
            },
            // headers: {
            //   Accept: "application/json",
            // },
          };
          // https://infofsg.com/allku/rrhh/
          return fetch(`https://api.fsg.ec/apiallku-private/api/fromapiexterna`, requestSettings).then((data) => {
            // return fetch(`https://infofsg.com/api/fromapiexterna`, requestSettings).then((data) => {
            if (!data.ok) {
              throw new Error("Respuesta de red no exitosa");
            }
            return data.json();
          });
        });

        const result = await Promise.all(requests);
        result.map((item) => llaves.push(item.llave));
        resolve({ ok: true, llaves });
      } catch (error: any) {
        reject({ ok: false, msg: error.message || "Error desconocido" });
      }
    });
  };

  const validateFiles = (a: Files) => {
    let archivos = [];

    let { foto_carnet, cedula_votacion, cedula_conyugue, hijos_discapacidad, licencia, cedulas_hijos } = a;

    if (foto_carnet && foto_carnet[0]) {
      archivos.push(foto_carnet[0]);
      foto_carnet[0].archivo = "foto";
    }
    if (cedula_votacion && cedula_votacion[0]) {
      archivos.push(cedula_votacion[0]);
      cedula_votacion[0].archivo = "cedula";
    }
    if (cedula_conyugue && cedula_conyugue[0]) {
      archivos.push(cedula_conyugue[0]);
      cedula_conyugue[0].archivo = "cedula-conyugue";
    }

    if (hijos_discapacidad && hijos_discapacidad[0]) {
      archivos.push(hijos_discapacidad[0]);
      hijos_discapacidad[0].archivo = "hijos-discapacidad";
    }

    if (cedulas_hijos && cedulas_hijos[0]) {
      archivos.push(cedulas_hijos[0]);
      cedulas_hijos[0].archivo = "cedula-hijos";
    }

    if (licencia && licencia[0]) {
      archivos.push(licencia[0]);
      licencia[0].archivo = "licencia";
    }
    return archivos;
  };

  const getCargos = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "apifsg-pr/rrhh/cargos", optionsPost({})).then((data) => data.json());

      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      // console.log(e)
      return { msg: "no_conexion", status: false };
    }
  }, [optionsPost]);

  const getNacionalides = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "rrhh/nacionalidad", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      // console.log(e)
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);

  const getTransporte = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "rrhh/tipo-transporte", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      // console.log(e)
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);

  const getSangre = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "rrhh/tipo-sangre", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      // console.log(e)
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);

  const getEstadoCivil = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "rrhh/estado-civil", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);

  const getTipoVehiculo = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "rrhh/tipo-vehiculo", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);

  const getTipoLicencia = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "rrhh/tipo-licencia", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);

  const getDestrezas = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "rrhh/tipo-destrezas", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);

  const saveData = async (formulario: any) => {
    try {
      const respuesta = await fetch(url + "rrhh/guardar", optionsPost(formulario)).then((data) => data.json());

      if (respuesta.ok) {
        return { respuesta, status: true };
      } else {
        return { respuesta: "Ocurrio un error al guardar", status: false };
      }
    } catch (error) {
      return { msg: "no_conexion", status: false };
    }
  };

  const getInstruccion = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "rrhh/tipo-instruccion", optionsGet).then((data) => data.json());
      if (respuesta.msg) {
        const { data } = respuesta;
        return { data, status: true };
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [optionsGet]);

  let array11 = [
    "cedula",
    "nombres",
    "apellidos",
    "correo_personal",
    "direccion",
    "fecha_nacimiento",
    "nacionalidad",
    "estatura",
    "peso",
    "telefono1",
    "lugar_nacimiento",
    "estado_civil",
    "telefono_emergencia2",
    "fecha_examen",
  ];

  let array12 = [
    "nombres_madre",
    "nombres_padres",
    "nombres_conyugue",
    "es_ingreso_adicional",
    "cual_ingreso",
    "monto_ingreso",
    "personas_residen",
    "cantidad_hijos",
    "buses_toma",
    "km_empresa",
    "fecha",
    "cargo",
    "es_relacion_parentesco",
    "nombres_relacion_parentesco",
    "tipo_parentesco",
    "residentes",
    "conyugue",
  ];

  let array13 = [
    "tipo_sangre",
    "fecha_examen",
    "es_cirugias",
    "cod_instruccion_academica",
    "titulo",
    "es_estudias",
    "que_estudias",
    "donde_estudias",
    "primaria",
    "secundaria",
    "modalidad",
  ];

  let array2 = ["es_computadora", "idiomas", "nivel_1", "nivel_2", "nivel_3", "nivel_4", "nivel_5"];
  let array3 = ["referencias", "idiomas"];
  let array5 = ["foto_carnet", "cedula_votacion", "cedula_conyugue"];
  let arrayterminos = ["rinterno", "rhigiene", "es_aceptacion_md", "es_aceptacion_dp"];

  return {
    arrayterminos,
    update,
    helperLlaves,
    validateFiles,
    getData,
    getCargos,
    getNacionalides,
    getSangre,
    getEstadoCivil,
    getTipoVehiculo,
    getTipoLicencia,
    getDestrezas,
    saveData,
    getEstadosIndividual,
    getEstados,
    getInstruccion,
    handleChange2,
    array11,
    array12,
    array13,
    array2,
    array3,
    array5,
    handleChange,
    getTransporte,
    form,
    fileSelected,
    setFileSelected,
    getInformacion,
    items,
    setActiveKey,
    setActiveKey2,
    activeKey,
    ubicacion,
    estado,
    setEstado,
    loader,
    setLoader,
    loaderGeneral,
  };
};
