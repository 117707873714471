import { Col, Row, DatePicker, Table, Card, Select, Button, Spin } from "antd";
import "moment/locale/es";

import { useVT } from "virtualizedtableforantd4";
import { ButtonExcel } from "components/otros/buttons";
import { DatosPeriodo, useFacturasPorCuentas } from "hooks/contabilidad/useFacturasPorCuentas";
const { Option } = Select;
export function FacturasPorCuentas() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  const {
    columns,
    filteredData,
    datosPeriodos,
    fechaInicio,
    fechaFin,
    setFechas,
    setCuenta,
    cuenta,
    estadoBotones,
    dataEstado,
    handleClickDownloadData,
    disabledDate,
    handleChangeButton,
  } = useFacturasPorCuentas();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Select
          allowClear
          showSearch
          placeholder="Seleccione una cuenta"
          style={{ width: "100%" }}
          optionFilterProp="label"
          onChange={(value) => setCuenta(value)}
        >
          {datosPeriodos?.map((opcion: DatosPeriodo) => (
            <Option key={opcion.value} value={opcion.value} label={opcion.label}>
              {opcion.label}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={24} sm={8} md={6} lg={5} xl={5}>
        <DatePicker.RangePicker
          value={[fechaInicio, fechaFin]} // Mantiene el estado sincronizado
          onCalendarChange={(dates) => {
            if (dates && dates[0]) {
              setFechas([dates[0], dates[1]]); // Actualiza las fechas seleccionadas
            } else {
              setFechas([null, null]); // Permite reiniciar el rango al borrar o seleccionar nuevo rango
            }
          }}
          onChange={(dates) => {
            if (!dates) {
              setFechas([null, null]); // Reinicia el rango cuando se borra
            }
          }}
          disabledDate={disabledDate}
        />
      </Col>
      <Col xs={24} sm={8} md={6} lg={5} xl={5}>
        <Button
          type="primary"
          style={{ marginLeft: "5px" }}
          loading={estadoBotones}
          disabled={cuenta === undefined || fechaInicio === null || fechaFin === null}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
      </Col>
      <Col xs={24} sm={8} md={6} lg={5} xl={5}>
        <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}>Descargar Datos</ButtonExcel>
      </Col>

      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          {!dataEstado && estadoBotones && (
            <div style={{ textAlign: "center" }}>
              <div style={{ margin: "0 auto" }}>
                <Spin />
              </div>
            </div>
          )}
          <Table
            columns={columns}
            dataSource={filteredData}
            components={vt}
            size="small"
            scroll={{ x: 900, y: "60vh" }}
            pagination={false}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default FacturasPorCuentas;
