import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../config/constants";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { optionsGet } from "config/methods";
import { useExport } from "hooks/otros/useExport";
type InventarioReporte = {
  key: React.Key;
  N: string;
  Cod_Bodega: number;
  establecimiento: string;
  PDV: string;
  ruc: string;
  nombre: string;
  Documento: string;
  Subtotal: number;
  IVA: number;
  Total: number;
  RetIVA: number;
  RetRenta: number;
};

export interface DatosPeriodo {
  label: string;
  value: number;
}

const breadcrumbs = [
  { Label: "Contabilidad", Url: "/contabilidad" },
  { Label: "Facturas por cuenta", Url: "/contabilidad/facturas-cuentas" },
];

const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`];
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`;
//const openKeys = [breadcrumbs[0].Label];
export function useFacturasPorCuentas() {
  useLayout(breadcrumbs, openKeys, selectKeySider);
  // useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);

  const [datos, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);
  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [estadoBotonNuevo, setEstadoBotonNuevo] = useState<boolean>(false);

  const [cuenta, setCuenta] = useState<number>();
  const { exportToExcel } = useExport();
  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [datosPeriodos, setDatosPeriodos] = useState<DatosPeriodo[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();

  const { isError } = useVerifyResponse();

  const getDatosCuentas = async (codCuenta: number, fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDataBoton(true);
    setEstadoBotonNuevo(false);
    try {
      const res = await fetch(
        url_interna + "api/contabilidad/facturas/obtener-facturas-cuentas",
        optionsPOST(
          JSON.stringify({
            codCuenta: codCuenta,
            fechaInicio: fechaInicio!.format("DD/MM/YYYY"),
            fechaFin: fechaFin!.format("DD/MM/YYYY"),
          }),
          {
            "api-token": "apitoken",
          }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: InventarioReporte[] = respuesta.data;
        setDataBoton(false);
        setDataEstado(true);
        setDatos(
          respuestas.map(
            ({
              key,
              N,
              Cod_Bodega,
              establecimiento,
              PDV,
              ruc,
              nombre,
              Documento,
              Subtotal,
              IVA,
              Total,
              RetIVA,
              RetRenta,
            }) => ({
              key,
              N,
              Cod_Bodega,
              establecimiento,
              PDV,
              ruc,
              nombre,
              Documento,
              Subtotal,
              IVA,
              Total,
              RetIVA,
              RetRenta,
            })
          )
        );
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  };
  const disabledDate = (current: moment.Moment) => {
    const currentYear = moment().year(); // Año actual

    if (!fechaInicio) {
      // Si no hay fechaInicio, deshabilitar fechas futuras y años distintos al actual
      return current && (current.year() !== currentYear || current > moment().endOf("day"));
    }

    const minDate = fechaInicio.clone().startOf("day");
    const maxDate = fechaInicio.clone().add(6, "months").add(1, "days").endOf("day");
    const today = moment().endOf("day");

    // Deshabilitar fechas fuera del rango permitido o años distintos al actual
    return current && (current.year() !== currentYear || current < minDate || current > maxDate || current > today);
  };

  const getDatosIniciales = useCallback(async () => {
    setDatosPeriodos([]);
    try {
      const res = await fetch(url_interna + "api/contabilidad/facturas/obtener-cuentas", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const respuestas: DatosPeriodo[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatosPeriodos(
            respuestas.map(({ label, value }) => ({
              label,
              value,
            }))
          );
        } else {
          setDatosPeriodos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);
  // Función para determinar el estilo basado en el estado
  const getOptionColor = (estado: number) => {
    switch (estado) {
      case 1:
        return "#ff4d4f"; // Rojo para estado 1
      case 2:
        return "#52c41a"; // Verde para estado 2
      default:
        return "#d9d9d9"; // Gris para otros estados
    }
  };

  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);


  const handleClickDownloadData = () => {

    if (filteredData.length>0){

    let datosParaExportar = [];

    datosParaExportar = filteredData.map(({ ...resto }) => resto);

    datosParaExportar = datosParaExportar.map(({ key, ...resto }) => resto);
    exportToExcel(
      datosParaExportar,
      "Reporte de cuenta " + fechaInicio!.format("DD/MM/YYYY") + " " + fechaFin!.format("DD/MM/YYYY") + " " + cuenta
    );
}
  };
  const handleChangeButton = () => {
    try {
      if (cuenta && fechaInicio && fechaFin) {
        getDatosCuentas(cuenta, fechaInicio, fechaFin);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.establecimiento?.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);
  // UseEffect para actualizar los datos al cambiar el mes
  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "N° ",
      dataIndex: "N",
      key: "N",
      //...getColumnSearchPropsNewVisorReporte("DescripcionProducto"),
      className: "letraPequenaVisor2",
      width: "40px",
    },
    {
      title: "CodBodega",
      dataIndex: "Cod_Bodega",
      key: "Cod_Bodega",
      //...getColumnSearchPropsNewVisorReporte("DescripcionProducto"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Establecimiento",
      dataIndex: "establecimiento",
      key: "establecimiento",

      className: "letraPequenaVisor2",
    },
    {
      title: "PDV",
      dataIndex: "PDV",
      key: "PDV",
      className: "letraPequenaVisor2",
    },

    {
      title: "RUC",
      dataIndex: "ruc",
      key: "ruc",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("ruc"),
      width: "110px",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("nombre"),
      width: "200px",
    },
    {
      title: "Documento",
      dataIndex: "Documento",
      key: "Documento",
      ...getColumnSearchPropsNewVisorReporte("Documento"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Subtotal",
      dataIndex: "Subtotal",
      key: "Subtotal",
      className: "letraPequenaVisor2",
    },
    {
      title: "Iva",
      dataIndex: "IVA",
      key: "IVA",
      align: "right",
      className: "letraPequenaVisor2",
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      align: "right",
      className: "letraPequenaVisor2",
    },
    {
      title: "RetIVA",
      dataIndex: "RetIVA",
      key: "RetIVA",
      align: "right",
      className: "letraPequenaVisor2",
    },
    {
      title: "RetRenta",
      dataIndex: "RetRenta",
      key: "RetRenta",
      align: "right",
      className: "letraPequenaVisor2",
    },
  ];

  return {
    columns,
    fechaInicio,
    fechaFin,
    setFechas,
    filteredData,
    datosPeriodos,
    setCuenta,
    cuenta,
    estadoBotones,
    dataEstado,
    handleClickDownloadData,
    disabledDate,
    estadoBotonNuevo,
    getOptionColor,
    handleChangeButton,
  };
}
