import { Row, Space } from "antd"
import { ColumCard, TypeCard } from "components/otros/ColumCard"
import {
  Reembolso,
  PoliticasComerciales,
  Procesos,
  RecursosHumanos,
  NoImage,
  Convenios,
  Franquiciados,
  Productos,
  LlamadosAtencion,
  Vacaciones,
  Menu,
  Perfiles,
  AsignarPerfilUsuario,
  AsignarMenuPerfil,
  Inventario,
  Sistemas,
  AsignarMenuBodega,
  Cajas,
  Auditoria,
  Mantenimiento,
  Report
} from "components/svg/Menu";
import { SettingOutlined, DollarOutlined } from "@ant-design/icons";
import { menuSistema } from "types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useScreenSize } from "hooks/otros/useScreenSize";

import styles from "pages/contentprincipal/principal.module.css"
import { SVGProps, useContext, useEffect, useMemo, useState } from "react"
import { UserContext } from "context/UserContext"
import { useLayout } from "hooks/otros/useLayout"

const svgProps = { fill: "#fff", width: "60px", height: "60px" }

export const ICONS = {
  Reembolsos: (props?: SVGProps<SVGSVGElement>) => (props ? <Reembolso {...props} /> : <Reembolso {...svgProps} />),
  "Políticas Comerciales": (props?: SVGProps<SVGSVGElement>) =>
    props ? <PoliticasComerciales {...props} /> : <PoliticasComerciales {...svgProps} />,
  Procesos: (props?: SVGProps<SVGSVGElement>) => (props ? <Procesos {...props} /> : <Procesos {...svgProps} />),
  Configuraciones: (props?: SVGProps<SVGSVGElement>) =>
    props ? (
      <SettingOutlined style={{ fontSize: "15px", color: "#000" }} />
    ) : (
      <SettingOutlined style={{ fontSize: "60px", color: "#fff" }} />
    ),
  "Recursos Humanos": (props?: SVGProps<SVGSVGElement>) =>
    props ? <RecursosHumanos {...props} /> : <RecursosHumanos {...svgProps} />,
  Indicadores: (props?: SVGProps<SVGSVGElement>) =>
    props ? <RecursosHumanos {...props} /> : <RecursosHumanos {...svgProps} />,
  Convenios: (props?: SVGProps<SVGSVGElement>) => (props ? <Convenios {...props} /> : <Convenios {...svgProps} />),
  Franquiciados: (props?: SVGProps<SVGSVGElement>) =>
    props ? <Franquiciados {...props} /> : <Franquiciados {...svgProps} />,
  Productos: (props?: SVGProps<SVGSVGElement>) => (props ? <Productos {...props} /> : <Productos {...svgProps} />),
  "Llamados de atención": (props?: SVGProps<SVGSVGElement>) =>
    props ? <LlamadosAtencion {...props} /> : <LlamadosAtencion {...svgProps} />,
  Vacaciones: (props?: SVGProps<SVGSVGElement>) => (props ? <Vacaciones {...props} /> : <Vacaciones {...svgProps} />),
  Menú: (props?: SVGProps<SVGSVGElement>) => (props ? <Menu {...props} /> : <Menu {...svgProps} />),
  Perfiles: (props?: SVGProps<SVGSVGElement>) => (props ? <Perfiles {...props} /> : <Perfiles {...svgProps} />),
  "Asignar perfil a usuario": (props?: SVGProps<SVGSVGElement>) =>
    props ? <AsignarPerfilUsuario {...props} /> : <AsignarPerfilUsuario {...svgProps} />,
  "Asignar menu a perfil": (props?: SVGProps<SVGSVGElement>) =>
    props ? <AsignarMenuPerfil {...props} /> : <AsignarMenuPerfil {...svgProps} />,
  Inventarios: (props?: SVGProps<SVGSVGElement>) => (props ? <Inventario {...props} /> : <Inventario {...svgProps} />),
  Sistemas: (props?: SVGProps<SVGSVGElement>) => (props ? <Sistemas {...props} /> : <Sistemas {...svgProps} />),
  Mantenimientos: (props?: SVGProps<SVGSVGElement>) => (props ? <Mantenimiento {...props} /> : <Mantenimiento {...svgProps} />),
  
  Marketing: (props?: SVGProps<SVGSVGElement>) => (props ? <Mantenimiento {...props} /> : <Mantenimiento {...svgProps} />), 

  Contabilidad: (props?: SVGProps<SVGSVGElement>) =>
    props ? (
      <DollarOutlined style={{ fontSize: "15px", color: "#000" }} />
    ) : (
      <DollarOutlined style={{ fontSize: "60px", color: "#fff" }} />
    ),
  Bodega: (props?: SVGProps<SVGSVGElement>) =>
    props ? <AsignarMenuBodega {...props} /> : <AsignarMenuBodega {...svgProps} />,
  Cajas: (props?: SVGProps<SVGSVGElement>) => (props ? <Cajas {...props} /> : <Cajas {...svgProps} />),
  "Auditoría de Procesos": (props?: SVGProps<SVGSVGElement>) =>
    props ? <Auditoria {...props} /> : <Auditoria {...svgProps} />,
  Reportes: (props?: SVGProps<SVGSVGElement>) => (props ? <Report {...props} /> : <Report {...svgProps} />),
};

const getLink = (menu: menuSistema[]): string => {
  return menu[0]?.enlace ? menu[0]?.enlace : getLink(menu[0].children ?? [])
}

export function Principal() {
  const location = useLocation()

  const { userData: dataUser } = useContext(UserContext)

  const [menuRender, setMenuRender] = useState<menuSistema[]>(dataUser?.menu ?? [])

  const breadcrumbs = useMemo(() => {
    if (location.pathname === "/" || !dataUser) return [{ Label: "" }]

    const arrPath = location.pathname.split("/").slice(1)
    let arr: { Label: JSX.Element | string }[] = []
    let lastChildren = dataUser.menu
    let enlaces = ""

    for (let index = 0; index < arrPath.length; index++) {
      enlaces += `/${arrPath[index]}`
      const returnFindFunction = (links: string) => (m: menuSistema) => m.enlace === links || `/${m.enlace}` === links
      const actual = lastChildren.find(returnFindFunction(enlaces))
      if (index === arrPath.length - 1) {
        arr.push({ Label: actual?.label ?? "" })
      } else {
        arr.push({ Label: <Link to={actual?.enlace ?? "/"}>{actual?.label ?? ""}</Link> })
        lastChildren = actual?.children ?? []
      }
    }
    return arr
  }, [dataUser, location])

  const openKeys: string[] = useMemo(() => {
    if (location.pathname === "/" || !dataUser) return []

    const arrPath = location.pathname.split("/").slice(1)
    let arr = []
    let lastChildren = dataUser.menu
    let prevKey = ""
    let enlaces = ""

    for (let index = 0; index < arrPath.length; index++) {
      enlaces += "/" + arrPath[index]
      const returnFindFunction = (links: string) => (m: menuSistema) => m.enlace === links || `/${m.enlace}` === links
      const actualKey = lastChildren.find(returnFindFunction(enlaces))
      arr.push(prevKey + actualKey?.label ?? "")
      lastChildren = actualKey?.children ?? []
      prevKey = actualKey?.label ?? ""
    }
    return arr
  }, [dataUser, location])

  useLayout(breadcrumbs, openKeys, "")

  const navigate = useNavigate()

  const { width } = useScreenSize()

  const isWeb = width >= 778

  useEffect(() => {
    if (!dataUser) return
    if (location.pathname === "/") {
      setMenuRender(dataUser.menu)
      return
    }

    const arrPath = location.pathname.split("/").slice(1)
    let lastChildren = dataUser.menu
    let enlaces = ""

    for (let index = 0; index < arrPath.length; index++) {
      enlaces += "/" + arrPath[index]
      const returnFindFunction = (links: string) => (m: menuSistema) => m.enlace === links || `/${m.enlace}` === links
      lastChildren = lastChildren.find(returnFindFunction(enlaces))?.children ?? []
    }

    setMenuRender(lastChildren)
  }, [dataUser, location])

  return (
    <Row wrap gutter={isWeb ? [16, 16] : [5, 5]} className={styles.rows}>
      {menuRender.map(({ key, label, enlace, children }) => {
        const link = enlace ? enlace : getLink(children ?? [])
        return (
          <ColumCard
            handleClick={() => navigate(link.at(0) === "/" ? link : `/${link}` ?? "/")}
            key={key}
            cardProps={{ className: styles["card-principal"] }}
            cardType={TypeCard.OnlyButton}
            colProps={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 6, style: { paddingRight: 0 } }}
            // buttonProps={{ icon: ICONS[label as keyof typeof ICONS], style: {width: '75px', height:'75px', display: 'flex', alignItems: 'center', justifyContent: 'center'}, type: 'primary' }}
          >
            <Space direction='vertical' className={styles["space-principal"]}>
              {location.pathname !== "/"
                ? (ICONS[label as keyof typeof ICONS] ?? (() => undefined))()
                : (ICONS[label as keyof typeof ICONS] ?? (() => <NoImage {...svgProps} />))()}
              <p
                className={styles.text}
                style={location.pathname !== "/" && !ICONS[label as keyof typeof ICONS] ? { fontSize: "2rem" } : {}}
              >
                {label}
              </p>
            </Space>
          </ColumCard>
        )
      })}
    </Row>
  )
}
