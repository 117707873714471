import { Moment } from "moment";
import {  useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";

type CargosTransferenciasReporte = {
  key: React.Key;
  Bodega: string;
  Observacion:string;
  Fecha: string;
  Total: number;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Logistica", Url: "/inventarios/logistica" },
  { Label: "Cargos por transferencias" },
];
//const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Productividad" }];

const openKeys = [
  breadcrumbs[0].Label,
  breadcrumbs[0].Label + "/" + breadcrumbs[1].Label,
];
export function useReporteCargosPorTransferencias() {
  useLayout(
    breadcrumbs,
    openKeys,
    breadcrumbs[1].Label + "/" + breadcrumbs[2].Label
  );
  const [datos, setDatos] = useState<CargosTransferenciasReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<
    CargosTransferenciasReporte[]
  >([]);
  const { getColumnSearchPropsNew } =
    useSearchTableNew<CargosTransferenciasReporte>();
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const [[fechaInicio, fechaFin], setFechas] = useState<
    [Moment | null, Moment | null]
  >([null, null]);
  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [estadoBotones, setDataBoton] = useState<boolean>(false);

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) =>
        item.Bodega.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const columns: TableColumnsType<CargosTransferenciasReporte> = [
    {
      title: "Bodega",
      dataIndex: "Bodega",
      key: "Bodega",
      ...getColumnSearchPropsNew("Bodega"),
    },
    {
        title: "Observación",
        dataIndex: "Observacion",
        key: "Observacion",
      },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      key: "Fecha",
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      align: "right",
      sorter: (a, b) => a.Total - b.Total,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <span style={{ color: record.Total === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.Total)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];
  const disabledDate = (current: moment.Moment) => {
    if (!fechaInicio) {
      return current && current > moment().endOf("day"); // Mientras no haya fecha inicial, deshabilita fechas futuras
    }
    const minDate = fechaInicio.clone().startOf("day");
    const maxDate = fechaInicio
      .clone()
      .add(6, "months")
      .add(1, "days")
      .endOf("day");
    const today = moment().endOf("day"); // Fecha actual (final del día)

    return (
      current && (current < minDate || current > maxDate || current > today)
    );
  };
  const getCargosTransferencias= async (fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDataBoton(true);
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener_cargos_transferencias",
        optionsPOST(
            JSON.stringify({ fechaInicio: fechaInicio!.format("DD/MM/YYYY"), fechaFin: fechaFin!.format("DD/MM/YYYY") }),
            {
              "api-token": "apitoken",
            }
          )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: CargosTransferenciasReporte[] = respuesta.data;
        setDataEstado(true);
        setDataBoton(false);
        setDatos(
          respuestas.map(({ Fecha, Bodega,Observacion, Total, key }) => ({
            Fecha,
            Bodega,
            Observacion,
            Total,
            key,
          }))
        );
      } else {
        setDataEstado(false);
        setDataBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataEstado(false);
      setDataBoton(false);
      return { msg: "no_conexion", status: false };
    }
  };
  const calcularTotales = () => {
    let total = 0.0;

    filteredData.forEach((item) => {
      total += item.Total;
    });
    total = parseFloat(total.toFixed(2));
    let valor = formateaCantidad(total);
    return { valor };
  };
  const handleChangeButton = () => {
    try {
      if (fechaInicio && fechaFin) {
        getCargosTransferencias(fechaInicio, fechaFin);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickDownloadData = () => {
    const datosParaExportar = datos.map(({ key, ...resto }) => resto);
    exportToExcel(
      datosParaExportar,
      "Cargos Transferencias"
    );
  };

  return {
    columns,
    filteredData,
    handleChangeButton,
    estadoBotones,
    disabledDate,
    dataEstado,
    getCargosTransferencias,
    searchText,
    optionsPost,
    fechaInicio,
    fechaFin,
    setFechas,
    handleClickDownloadData,
    calcularTotales,
  };
}
