import { Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import { optionsPOST, url, url_interna } from "config/constants";
import { LayoutContext } from "context/LayoutContext";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useExport } from "hooks/otros/useExport";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { optionsGet } from "config/methods";
import { RangePickerProps } from "antd/lib/date-picker";

const breadcrumbs = [{ Label: "Inventarios Consolidados" }];

interface PropsTableReport {
  key: number;
  Cantidad: number;
  "Unidad medida": number;
  Bodega: string;
  "Codigo producto homologado": string;
  Impuesto: "Si";
  "Nombre producto": string;
  "Precio unitario de ingreso sin impuesto": number;
}
interface BodegaSelect {
  value: number;
  label: string;
}

export function useInventarioBodegas() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  const [dataTable, setDataTable] = useState<PropsTableReport[]>([]);
  const { exportToExcel } = useExport();
  const [loader, setLoader] = useState<boolean>(false);
  const [typeSelect, setTypeSelect] = useState<number>();
  const [bodegas, setBodegas] = useState<BodegaSelect[]>([]);

  const [form] = Form.useForm();

  const disabledFutureDate: RangePickerProps["disabledDate"] = (current) => {
    // return current && current >= moment().endOf("day").subtract(1, "day");
    return current && current >= moment().endOf("day");
  };

  const dataSelectOptions = [
    {
      value: 1,
      label: "CEDI",
    },
    {
      value: 2,
      label: "Logística Inversa",
    },
    {
      value: 3,
      label: "Farmacias",
    },
    {
      value: 5,
      label: "Psicotrópicos",
    },
    {
      value: 6,
      label: "Valores por establecimiento",
    },
    {
      value: 4,
      label: "Farmacia Individual",
    },
  ];

  const headTableReport: ColumnsType<PropsTableReport> = [
    {
      title: "Codigo producto homologado",
      dataIndex: "Codigo producto homologado",
      key: "Codigo producto homologado",
    },
    {
      title: "Nombre producto",
      dataIndex: "Nombre producto",
      key: "Nombre producto",
    },
    {
      title: "Cantidad",
      dataIndex: "Cantidad",
      key: "Cantidad",
      align: "right",
      width: "7%",
    },
    {
      title: "Unidad medida",
      dataIndex: "Unidad medida",
      key: "Unidad medida",
      align: "right",
      width: "12%",
    },
    {
      title: "Precio unitario de ingreso sin impuesto",
      dataIndex: "Precio unitario de ingreso sin impuesto",
      key: "Precio unitario de ingreso sin impuesto",
      align: "right",
    },
    {
      title: "Impuesto",
      dataIndex: "Impuesto",
      key: "Impuesto",
      width: "7%",
    },
    {
      title: "Bodega",
      dataIndex: "Bodega",
      key: "Bodega",
      width: "7%",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "7%",
      align: "right",
    },
  ];

  const headTableReport_Bode: ColumnsType<PropsTableReport> = [
    {
      title: "Cod_Bodega",
      dataIndex: "Cod_Bodega",
      key: "Cod_Bodega",
      width: "9%",
    },
    {
      title: "Establecimiento",
      dataIndex: "Establecimiento",
      key: "Establecimiento",
      width: "12%",
    },
    {
      title: "Descripcion_SG",
      dataIndex: "Descripcion_SG",
      key: "Descripcion_SG",
    },
    {
      title: "Descripcion_Sri",
      dataIndex: "Descripcion_Sri",
      key: "Descripcion_Sri",
    },
    {
      title: "Valor_Sin_Psicotropicos",
      dataIndex: "Valor_Sin_Psicotropicos",
      key: "Valor_Sin_Psicotropicos",
      align: "right",
      width: "16%",
    },
    {
      title: "Valor_Psicotropicos",
      dataIndex: "Valor_Psicotropicos",
      key: "Valor_Psicotropicos",
      align: "right",
      width: "16%",
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      width: "7%",
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const handleClickGenerar = () => {
    setLoader(true);
    form
      .validateFields()
      .then(async (values) => {
        setLoader(true);
        setDataTable([]);
        const respuesta = await fetch(
          `${url_interna}api/inventarios/stock_bodegas?cache_buster=${new Date().getTime()}`,
          optionsPOST(JSON.stringify({ values }), {
            "api-token": "apitoken",
          })
        ).then((data) => data.json());

        if (!respuesta.msg) {
          Swal.fire("Error", "No existe información!", "error");
          setLoader(false);
          return;
        }
        setDataTable(respuesta.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire("Error", "Complete los campos requeridos!", "error");
      });
  };

  const handleClickDownloadDataPrincipal = () => {
    if (!dataTable) return;
    const datosParaExportar = dataTable.map(({ key, ...resto }) => resto);
    exportToExcel(
      datosParaExportar,
      `Inventario_Consolidado_${
        form.getFieldsValue().cod_tipo === 1
          ? "Cedi"
          : form.getFieldsValue().cod_tipo === 2
          ? "Logistica_Inversa"
          : form.getFieldsValue().cod_tipo === 3
          ? "Farmacias"
          : form.getFieldsValue().cod_tipo === 4
          ? `Farmacia_${
              bodegas.find((bodega) => bodega.value === form.getFieldsValue().cod_farmacia)?.label || "Desconocido"
            }`
          : form.getFieldsValue().cod_tipo === 5
          ? "Psicotropico"
          : "Valores por establecimiento"
      }_${moment().format("YYYY-MM-DD_HH-mm-ss")}`
    );
  };

  const handleChangeSelect = async (value: number) => {
    form.setFieldsValue({ cod_farmacia: null });
    setBodegas([]);
    setDataTable([]);
    setTypeSelect(value);
    if (value === 4) {
      try {
        const respuesta = await fetch(url + "apifsg-pr/inventarios/bodegas", optionsGet()).then((data) => data.json());
        if (respuesta.msg) {
          setBodegas(respuesta.data);
        } else {
          Swal.fire("Error", "Ocurrio un error en la información", "error");
          return { msg: "Ocurrio un error", status: false };
        }
      } catch (e) {
        return { msg: "no_conexion", status: false };
      }
    }
  };

  useEffect(() => {
    setOpenKeys(["Inventarios", "Inventarios Consolidados"]);
    setSelectedKeys(["Inventarios/Inventarios Consolidados"]);
  }, [setOpenKeys, setSelectedKeys]);

  return {
    handleClickDownloadDataPrincipal,
    form,
    dataSelectOptions,
    headTableReport,
    handleClickGenerar,
    dataTable,
    setDataTable,
    loader,
    handleChangeSelect,
    typeSelect,
    bodegas,
    headTableReport_Bode,
    disabledFutureDate,
  };
}
