import { Moment } from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../config/constants";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { DatePickerProps, TableColumnsType, Upload, UploadFile, UploadProps } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { optionsGet, swalError, swalSuccess } from "config/methods";
import { UserContext } from "context/UserContext";
import { useExport } from "hooks/otros/useExport";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
type InventarioReporte = {
  CodProducto?: number;
  Producto?: string;
  Laboratorio: string;
  Fecha?: string;
  key?: number;
};

export interface DatosPeriodo {
  label: string;
  value: number;
  estado: number;
}
const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Crear inventario bodega" }];

const openKeys = [breadcrumbs[0].Label];
export function useCrearInventarioBodega() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [mes, setMes] = useState<Moment>(moment());

  const [datos, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);

  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [estadoBotonNuevo, setEstadoBotonNuevo] = useState<boolean>(false);

  const [fileCuotas, setFileCuotas] = useState<UploadFile>();
  const [datoComprueba, setDatoComprueba] = useState<number>(0);
  const [bodega, setBodega] = useState<number>();
  const { exportToExcel } = useExport();
  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [datosPeriodos, setDatosPeriodos] = useState<DatosPeriodo[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();

  const { userData } = useContext(UserContext);
  const { isError } = useVerifyResponse();

  const getDatosBodegaAuditada = async (codBodega: number) => {
    setDataEstado(false);
    setDataBoton(true);
    setEstadoBotonNuevo(false);
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener_datos_inventario_generado",
        optionsPOST(JSON.stringify({ codBodega: codBodega }), { "api-token": "apitoken" })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: InventarioReporte[] = respuesta.data;
        setDataBoton(false);
        setDataEstado(true);
        if (respuestas.length > 0 && respuestas[0].Fecha) {
          const fechaAPI = respuestas[0].Fecha;
          const fechaMoment = moment(fechaAPI, "DD/MM/YYYY").startOf("day");
          setEstadoBotonNuevo(false);
          /*if (fechaMoment < moment().startOf("day")) {
            setEstadoBotonNuevo(true);
          } else {
            // La fecha de la API no es anterior al día actual
            setEstadoBotonNuevo(false);
          }*/
          if (fechaMoment.isValid()) {
            setMes(fechaMoment);
          } else {
            setMes(moment());
            console.error("Fecha invalida recibida de la API:", fechaAPI);
          } // Aquí se establece la fecha
        }
        setDatos(
          respuestas.map(({ key, CodProducto, Producto, Laboratorio, Fecha }) => ({
            key,
            CodProducto,
            Producto,
            Laboratorio,
            Fecha,
          }))
        );
        setDatoComprueba(1);
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  };
  const handleChangeButtonGuardar = async () => {
    await Swal.fire({
      title: "Guardar inventario",
      text: "¿Está seguro de registrar este inventario?, si presiona en aceptar se registrará el inventario",
      icon: "question",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonColor: "#8b0505",
    }).then(async (result) => {
      if (result.isConfirmed && bodega !== undefined && bodega !== 0 && mes !== undefined && datos.length > 0) {
        await registrarInventario(bodega, mes, datos, datoComprueba);
        //await terminarInventario(datos[0].C_Inventario_Cab, bodega);
      } else {
        swalError({ text: "Seleccione nuevamente la bodega" });
      }
    });
  };
  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    // Permite seleccionar desde la fecha actual hasta un mes después
    const today = moment().startOf("day"); // Comienza desde el inicio del día actual
    const oneMonthLater = moment().add(1, "month").endOf("day"); // Extiende hasta el final del día, un mes después
    return current && (current < today || current > oneMonthLater);
  };
  const registrarInventario = async (codBodega: number, fecha: Moment, data: InventarioReporte[], datoComprueba: number) => {
    //setDataBotonFin(true);

    //setMes(moment());
    const codUsuario = userData?.id;
    try {
      const result = await fetch(
        url_interna + "api/inventarios/registrar_inventario_nuevo_metodo_inventario",
        optionsPOST(
          JSON.stringify({
            bodega: codBodega,
            fecha: fecha.format("DD/MM/YYYY HH:mm:ss"),
            jsonProductos: data,
            codUsuario: codUsuario,
            datoComprueba: datoComprueba,
          }),
          { "api-token": "apitoken" }
        )
      );
      if (isError(result)) throw new Error();
      let text = await result.json();
      if (text["msg"] !== "err") {
        setDatoComprueba(0);
        swalSuccess({ text: "Inventario registrado correctamente" });
        setDatos([]);
        getDatosIniciales();
      } else {
        // setDataBotonFin(false);
        swalError({ text: "No se pudo registrar el inventario" });
      }
    } catch (e) {
      // setDataBotonFin(false);
      console.log(e);
      swalError({ text: "No se pudo registrar el inventario" });
    }
  };
  const getDatosIniciales = useCallback(async () => {
    setDatosPeriodos([]);
    try {
      const res = await fetch(url_interna + "api/inventarios/obtener_bodegas_inventario_nuevo", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const respuestas: DatosPeriodo[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatosPeriodos(
            respuestas.map(({ label, value, estado }) => ({
              label,
              value,
              estado,
            }))
          );
        } else {
          setDatosPeriodos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);
  // Función para determinar el estilo basado en el estado
  const getOptionColor = (estado: number) => {
    switch (estado) {
      case 1:
        return "#ff4d4f"; // Rojo para estado 1
      case 2:
        return "#52c41a"; // Verde para estado 2
      default:
        return "#d9d9d9"; // Gris para otros estados
    }
  };

  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);

  useEffect(() => {
    let estados;
    setDatos([]);
    setDatoComprueba(0);
    if (bodega) {
      estados = datosPeriodos.find((e) => e.value === bodega)?.estado;
      if (estados === 1) {
        getDatosBodegaAuditada(bodega);
      }
    }
  }, [bodega]);

  const handleBeforeUpload: UploadProps["beforeUpload"] = (file) => {
    setFileCuotas(file);
    return Upload.LIST_IGNORE;
  };
  useEffect(() => {
    if (!fileCuotas) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const codProductoIndex = (sheetData[0] as string[]).findIndex((c) => c.toLowerCase() === "codproducto");
      const productoIndex = (sheetData[0] as string[]).findIndex((c) => c.toLowerCase() === "producto");
      const laboratorioIndex = (sheetData[0] as string[]).findIndex((c) => c.toLowerCase() === "laboratorio");
      const datosFiltrados = (sheetData.slice(1) as string[][]).filter((row) => {
        // Verificar que todas las columnas requeridas tengan contenido
        return row[codProductoIndex] && row[productoIndex] && row[laboratorioIndex];
      });
      setDatos(
        datosFiltrados.map((row) => ({
          key: parseInt(row[codProductoIndex]),
          CodProducto: parseInt(row[codProductoIndex]),
          Producto: row[productoIndex],
          Laboratorio: row[laboratorioIndex],
        }))
      );
    };
    reader.readAsBinaryString(fileCuotas as unknown as Blob);
  }, [fileCuotas]);

  const handleClickDownloadData = () => {
    let datosParaExportar = [];

    // Verifica si hay datos para exportar o solo exporta los encabezados de columna
    if (filteredData.length > 0) {
      datosParaExportar = filteredData.map(({ ...resto }) => resto);
    } else {
      // Crear una entrada vacía que coincide con el tipo InventarioReporte
      const emptyEntry: InventarioReporte = {
        CodProducto: undefined,
        Producto: "",
        Laboratorio: "",
        Fecha: "", // El número debe ser válido, 0 es un placeholder común
        key: undefined, // React.Key puede ser una cadena o un número; aquí usamos cadena vacía
      };

      datosParaExportar.push(emptyEntry);
    }
    datosParaExportar = datosParaExportar.map(({ key, Fecha, ...resto }) => resto);
    exportToExcel(datosParaExportar, "Plantilla para productos inventario");
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Producto?.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);
  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Código Producto",
      dataIndex: "CodProducto",
      key: "CodProducto",
      //...getColumnSearchPropsNewVisorReporte("DescripcionProducto"),
      className: "letraPequenaVisor2",
      width: "150px",
    },
    {
      title: "Producto",
      dataIndex: "Producto",
      key: "Producto",
      ...getColumnSearchPropsNewVisorReporte("Producto"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Laboratorio",
      dataIndex: "Laboratorio",
      key: "NombreLaboratorio",
      /*  filters: [...new Set(filteredData.map(({ Laboratorio }) => Laboratorio))].map((NombreLaboratorio) => ({
        text: NombreLaboratorio,
        value: NombreLaboratorio,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Laboratorio,*/
      className: "letraPequenaVisor2",
    },
  ];

  return {
    columns,
    mes,
    setMes,
    filteredData,
    datosPeriodos,
    setBodega,
    bodega,
    estadoBotones,
    dataEstado,
    handleClickDownloadData,
    handleBeforeUpload,
    disabledDate,
    estadoBotonNuevo,
    getOptionColor,
    handleChangeButtonGuardar,
  };
}
