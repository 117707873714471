import { useEffect, useContext, useCallback, useState } from "react";
import { LayoutContext } from "../../../context/LayoutContext";
import { url } from "../../../config/constants";
import { useBreadCrumbs } from "hooks/otros/useBreadCrumbs";
import { useLocalStorage } from "../../otros/useLocalStorage";
import { ColumnsType } from "antd/lib/table";
import { PropsFormu } from "pages/contentrecursoshumanos/contentactualizacion/RevisarInformacion";

import { Button, Tag } from "antd";
import { useSearchTable } from "hooks/otros/useSearchTable";
import { optionsPost, swalWarning } from "config/methods";
import { EyeOutlined } from "@ant-design/icons";

export interface DataTypeRevisar {
  key: string;
  nombres: string;
  apellidos: number;
  cedula: string;
  correo_personal: string;
  telefono_1: string;
  direccion: string;
}
interface DataPeriodo {
  value: number;
  label: string;
}

const breadCrumbs = [
  {
    Label: "INFORMACIÓN DE LOS EMPLEADOS QUE HAN ACTUALIZADO SUS DATOS PERSONALES",
    Url: "rrhh/revisar-informacion",
  },
];

export function useRevisarInformacion() {
  const { setOpenKeys, setSelectedKeys } = useContext(LayoutContext);
  let { optionsGet } = useLocalStorage();
  const [row, setRow] = useState<PropsFormu>();
  const { getColumnSearchProps } = useSearchTable<any>();
  const [periodos, setPeriodos] = useState<DataPeriodo[]>([]);
  const [enviadas, setEnviadas] = useState<PropsFormu[] | undefined>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [valuePeriodo, setValuePeriodo] = useState<number>(3);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useBreadCrumbs(breadCrumbs);
  useEffect(() => {
    setOpenKeys(["Recursos Humanos/Actualizar Información", "Recursos Humanos"]);
    setSelectedKeys(["Actualizar Información/Revisar Información"]);
  }, [setOpenKeys, setSelectedKeys]);

  const handleChangePeriodo = (value: number) => {
    setValuePeriodo(value);
    getEnviadas(value);
  };

  const getEnviadas = useCallback(async (cod_periodo: number = 3) => {
    try {
      setEnviadas([]);
      setLoading(true);
      const respuesta = await fetch(url + "rrhh/informacion-enviada", optionsPost({ cod_periodo })).then((data) =>
        data.json()
      );

      if (respuesta.msg) {
        const { data } = respuesta;
        let newData = data.map((el: any) => ({
          ...el,
          hijos18: el.hijos.length,
        }));
        setEnviadas(newData);
      } else {
        swalWarning({ text: "Ocurrio un error" });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      swalWarning({ text: "Ocurrio un error" });
    }
  }, []);

  const getPeriodos = useCallback(async () => {
    try {
      const respuesta = await fetch(url + "rrhh/periodos", optionsGet).then((data) => data.json());

      if (respuesta.msg) {
        const { data } = respuesta;
        setPeriodos(data);
      } else {
        swalWarning({ text: "Ocurrio un error" });
      }
    } catch (e) {
      return { data: [], status: false };
    }
  }, [optionsGet]);

  useEffect(() => {
    getPeriodos();
    getEnviadas();
  }, [getPeriodos, getEnviadas]);

  //PropsFormu

  const getColorForStatus = (status: string) => {
    switch (status) {
      case "Pendiente":
        return "orange";
      case "Edicion":
        return "blue";
      case "Correcion":
        return "red";
      case "Aprobado":
        return "green";
      default:
        return "default";
    }
  };

  const columns: ColumnsType<PropsFormu> = [
    {
      title: "Nombres",
      dataIndex: "nombres",
      key: "nombres",
    },
    {
      title: "Apellidos",
      dataIndex: "apellidos",
      key: "apellidos",
      ...getColumnSearchProps("apellidos"),
    },
    {
      title: "Cédula",
      dataIndex: "cedula",
      key: "cedula",
      ...getColumnSearchProps("cedula"),
    },
    {
      title: "Correo Personal",
      dataIndex: "correo_personal",
      key: "correo_personal",
    },
    {
      title: "Telefono",
      dataIndex: "telefono1",
      key: "telefono1",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (estado) => {
        return <Tag color={getColorForStatus(estado)}>{estado}</Tag>;
      },
    },
    {
      title: "Accion",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            style={{ cursor: "pointer" }}
            id='nombre'
            onClick={() => {
              setRow(record);
              showModal();
            }}
          >
            <EyeOutlined />
            {/* corregir esto */}
            {/* {record.cod_periodo === 2 && <EyeOutlined />} */}
          </Button>
        );
      },
    },
  ];

  return {
    handleChangePeriodo,
    columns,
    isModalOpen,
    handleOk,
    handleCancel,
    showModal,
    row,
    periodos,
    enviadas,
    loading,
    valuePeriodo,
    getEnviadas,
    setEnviadas,
  };
}
