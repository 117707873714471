import { Moment } from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { optionsPOST, url, url_interna } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { Button, TableColumnsType, Tag } from "antd";

import { useLayout } from "hooks/otros/useLayout";
import { EyeOutlined } from "@ant-design/icons";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { optionsGet, swalError, swalSuccess } from "config/methods";
import Swal from "sweetalert2";
import { UserContext } from "context/UserContext";
import { useExport } from "hooks/otros/useExport";

type InventarioReporte = {
  DescripcionProducto: string;
  NombreLaboratorio: string;
  Unidad: number;
  Fraccion: number;
  CantReal: number;
  CostoReal:number;
  CajasAuditadas: number;
  FraccionesAuditadas: number;
  CantRealAuditada: number;
  CostoEscaneado:number;
  Estado: string;
  FechaEscaneo: string;
  C_Inventario_Cab: number;
  Cod_Estado: number;
  Estado_Reconteo: number;
  Costo_Promedio:number;
  Diferencia:number;
  key: React.Key;
};
type InventarioReconteo = {
  DescripcionProducto: string;
  NombreLaboratorio: string;
  Cant_Real: number;
  CajasRecontadas: number;
  FraccionesRecontadas: number;
  CantRealReconteo: number;
  Estado: string;
  Fecha_Reconteo: string;
  C_Inventario_Cab: number;
  Cod_Estado: number;
  Estado_Reconteo: number;
  persona:string;
  key: React.Key;
};

type DatosInventariosEspecial = {
  Cod_Usuario: number;
  Nombres_Rol: string;
  Cajas_Buenas: number;
  Fracciones_Buenas: number;
  CantidadRealAuditada: number;
  FechaEscaneo: string;
  key: React.Key;
};
type DatosPeriodo = {
  label: string;
  value: string;
};

type estado = {
  label: string;
  value: string;
};
const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Control inventario" }];

const openKeys = [breadcrumbs[0].Label];
export function useControlInventarioEspecial() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [mes, setMes] = useState<Moment>(moment());
  const [datos, setDatos] = useState<InventarioReporte[]>([]);

  const { exportToExcel } = useExport();
  const [datosReconteo, setDatosReconteo] = useState<InventarioReconteo[]>([]);
  const [searchText] = useState("");

  const [searchTextReconteo] = useState("");

  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);

  const [filteredDataReconteo, setFilteredDataReconteo] = useState<InventarioReconteo[]>([]);

  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [estadoBotonFinalizar, setEstadoFinalizar] = useState<boolean>(false);

  const [estadoBotonReconteo, setEstadoReconteo] = useState<boolean>(false);

  const [bodega, setBodega] = useState<number>();

  const [tipo, setEstadoTipo] = useState<number>();

  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [estadoBotonesFin, setDataBotonFin] = useState<boolean>(false);
  const [datosHistoricos, setDatosHistoricos] = useState<DatosInventariosEspecial[]>([]);
  const [datosPeriodos, setDatosPeriodos] = useState<DatosPeriodo[]>([]);
  const [datosTipos, setDatosTipo] = useState<estado[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();

  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte2 } = useSearchTableNew<InventarioReconteo>();
  const [isModalOpenHistorico, setIsModalOpenHistorico] = useState<boolean>(false);
  const { userData } = useContext(UserContext);
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const showModalHistorico = () => {
    setIsModalOpenHistorico(true);
  };
  const handleCancelHistorico = () => {
    setIsModalOpenHistorico(false);
  };
  const datosDeEstado = [
    { label: "Inventario", value: "1" },
    { label: "Reconteo", value: "2" },
  ];
  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };
  const getBodegas = async (codBodega: number, fecha: Moment, tipo: number) => {
    setDataEstado(false);
    setDataBoton(true);
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener_datos_principales_inventario_especial",
        optionsPost({ codBodega: codBodega, mes: fecha.format("YYYYMM"), tipo: tipo })
      );

      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        setDataEstado(true);
        setDataBoton(false);
        console.log(respuesta.data[0].C_Inventario_Cab);
        console.log(tipo);
        if (tipo === 1) {
          const respuestas: InventarioReporte[] = respuesta.data;
          
          setEstadoFinalizar(!respuesta.data[0].Estado_Reconteo || respuesta.data[0].Cod_Estado !== 1);
          console.log(estadoBotonFinalizar);
          setEstadoReconteo(respuesta.data[0].Estado_Reconteo);
          setDatos(
            respuestas.map(
              ({
                key,
                DescripcionProducto,
                NombreLaboratorio,
                Unidad,
                Fraccion,
                CantReal,
                CostoReal,
                CajasAuditadas,
                FraccionesAuditadas,
                CantRealAuditada,
                CostoEscaneado,
                Diferencia,
                Estado,
                FechaEscaneo,
                C_Inventario_Cab,
                Cod_Estado,
                Estado_Reconteo,
                Costo_Promedio,
                
              }) => ({
                key,
                DescripcionProducto,
                NombreLaboratorio,
                Unidad,
                Fraccion,
                CantReal,
                CostoReal,
                CajasAuditadas,
                FraccionesAuditadas,
                CantRealAuditada,
                CostoEscaneado,
                Diferencia,
                Estado,
                FechaEscaneo,
                C_Inventario_Cab,
                Cod_Estado,
                Estado_Reconteo,
                Costo_Promedio,
                
              })
            )
          );
        } else {
          const respuestas: InventarioReconteo[] = respuesta.data;
          console.log(respuestas);
          setEstadoFinalizar(!respuesta.data[0].Estado_Reconteo || respuesta.data[0].Cod_Estado !== 1 );
          setEstadoReconteo(respuesta.data[0].Estado_Reconteo);
          setDatosReconteo(
            respuestas.map(
              ({
                key,
                DescripcionProducto,
                NombreLaboratorio,
                CajasRecontadas,
                FraccionesRecontadas,
                Cant_Real,
                CantRealReconteo,
                Estado,
                Fecha_Reconteo,
                C_Inventario_Cab,
                Cod_Estado,
                Estado_Reconteo,
                persona
              }) => ({
                key,
                DescripcionProducto,
                NombreLaboratorio,
                CajasRecontadas,
                FraccionesRecontadas,
                Cant_Real,
                CantRealReconteo,
                Estado,
                Fecha_Reconteo,
                C_Inventario_Cab,
                Cod_Estado,
                Estado_Reconteo,
                persona
              })
            )
          );
        }
      } else {
        setDataEstado(false);
        setDataBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      console.log(e);
      setDataEstado(false);
      setDataBoton(false);
      return { msg: "no_conexion", status: false };
    }
  };

  const getDatosIniciales = useCallback(async () => {
    setDatosPeriodos([]);
    try {
      const res = await fetch(url_interna + "api/inventarios/obtener_bodegas_control_inventario", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const respuestas: DatosPeriodo[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatosPeriodos(
            respuestas.map(({ label, value }) => ({
              label,
              value,
            }))
          );

          setDatosTipo(datosDeEstado);
        } else {
          setDatosPeriodos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);
  const getDatosHistoricoInventario = async (codProducto: React.Key, codInventario: number) => {
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_datos_inventario_especial_especifico",
        optionsPost({ codProducto: codProducto, codInventario: codInventario })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const datosDecodificados: DatosInventariosEspecial[] = respuesta.data;
        //setDatosHistoricos(datosDecodificados);
        setDatosHistoricos(
          datosDecodificados.map(({ Cod_Usuario, Nombres_Rol, Cajas_Buenas, Fracciones_Buenas, CantidadRealAuditada, FechaEscaneo, key }) => ({
            Cod_Usuario,
            Nombres_Rol,
            Cajas_Buenas,
            Fracciones_Buenas,
            CantidadRealAuditada,
            FechaEscaneo,
            key,
          }))
        );
        showModalHistorico();
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      console.log(e);
      return { msg: "no_conexion", status: false };
    }
  };
  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);
  /* const setRowClassName = (record: DatosInventariosEspecial) => {
    if (record.ValorDiferencia > 0) {
      return "highlight-row"; // Esta es la clase CSS que se aplicará
    }
    return ""; // No se aplica ninguna clase especial si no se cumplen las condiciones
  };*/
  const terminarInventario = async (codInventario: number, codBodega: number) => {
    setDataBotonFin(true);
    const codUsuario = userData?.id;
    try {
      const result = await fetch(
        url_interna + "api/inventarios/terminar_inventario_especial",
        optionsPOST(
          JSON.stringify({
            codInventario,
            codUsuario,
            codBodega,
          }),
          { "api-token": "apitoken" }
        )
      );
      if (isError(result)) throw new Error();
      let text = await result.json();

      if (text["msg"] !== "err") {
        setDataBotonFin(false);
        setDataEstado(false);
        swalSuccess({ text: "Inventario finalizado correctamente" });
      } else {
        setDataBotonFin(false);
        swalError({ text: `No se pudo terminar el inventario:  ${text["data"]}` });
      }
    } catch (e) {
      setDataBotonFin(false);
      console.log(e);
      swalError({ text: `No se pudo terminar el inventario ${e}` });
    }
  };

  const empezarReconteo = async (codInventario: number, codBodega: number) => {
    setEstadoReconteo(false);
    //setDataBotonFin(true);
    const codUsuario = userData?.id;
    try {
      const result = await fetch(
        url_interna + "api/inventarios/empezar_reconteo_inventario",
        optionsPOST(
          JSON.stringify({
            codInventario,
            codUsuario,
            codBodega,
          }),
          { "api-token": "apitoken" }
        )
      );
      if (isError(result)) throw new Error();
      let text = await result.json();
      if (text["msg"] !== "err") {
        setEstadoReconteo(false);
        //setDataBotonFin(false);
        setDataEstado(false);
        swalSuccess({ text: "Reconteo iniciado correctamente" });
        if (bodega !== undefined && bodega >= 0 && mes) {
          getBodegas(bodega, mes, 1);
        }
      } else {
        setEstadoReconteo(true);
        //setDataBotonFin(false);
        swalError({ text: "No se pudo iniciar el reconteo" });
      }
    } catch (e) {
      setEstadoReconteo(true);
      // setDataBotonFin(false);
      console.log(e);
      swalError({ text: "No se pudo iniciar el reconteo" });
    }
  };

  /*useEffect(() => {
    if (bodega !== undefined && bodega >= 0 && mes && tipo === 1) {
      getBodegas(bodega, mes, 1);
    } else if (bodega !== undefined && bodega >= 0 && mes && tipo === 2) {
      getBodegas(bodega, mes, 2);
    }
  }, [tipo,bodega,mes]);*/

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.DescripcionProducto.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  useEffect(() => {
    if (!searchTextReconteo) {
      setFilteredDataReconteo(datosReconteo); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datosReconteo.filter((item) => item.DescripcionProducto.toLowerCase().includes(searchTextReconteo.toLowerCase()));
      setFilteredDataReconteo(filtered);
    }
  }, [searchTextReconteo, datosReconteo]);

  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Producto",
      dataIndex: "DescripcionProducto",
      key: "DescripcionProducto",
      ...getColumnSearchPropsNewVisorReporte("DescripcionProducto"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Laboratorio",
      dataIndex: "NombreLaboratorio",
      key: "NombreLaboratorio",
      filters: [...new Set(filteredData.map(({ NombreLaboratorio }) => NombreLaboratorio))].map((NombreLaboratorio) => ({
        text: NombreLaboratorio,
        value: NombreLaboratorio,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.NombreLaboratorio,
      className: "letraPequenaVisor2",
    },

    {
      title: "Fecha registro",
      dataIndex: "FechaEscaneo",
      key: "FechaEscaneo",
      sorter: (a, b) => moment(a.FechaEscaneo).unix() - moment(b.FechaEscaneo).unix(),
      sortDirections: ["descend", "ascend"],
      className: "letraPequenaVisor2",
    },
    {
      title: "Cant Real",
      dataIndex: "CantReal",
      align: "right",
      className: "letraPequenaVisor2",
      width: "100px",
      render: (_, record) => (
        <span style={{ color: record.CantReal === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.CantReal)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Cant real auditada",
      dataIndex: "CantRealAuditada",
      align: "right",
      className: "letraPequenaVisor2",
      width: "130px",
      render: (_, record) => (
        <span
          style={{
            color: record.CantRealAuditada === 0 ? "lightgray" : "black",
          }}
        >
          {formateaCantidad(record.CantRealAuditada)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Estado",
      width: "100px",
      dataIndex: "Estado",
      key: "Estado",
      render: (value: String) =>
        value === "Cuadrado" ? (
          <Tag color="success">Cuadrado</Tag>
        ) : value === "Faltante" ? (
          <Tag color="red">Faltante</Tag>
        ) : (
          <Tag color="warning">Sobrando</Tag>
        ),

      filters: [...new Set(filteredData.map(({ Estado }) => Estado))].map((Estado) => ({
        text: Estado,
        value: Estado,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Estado,
      className: "letraPequenaVisor2",
    },
    {
      title: "Datos",
      width: "80px",
      render: (record) => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            if (record.CantRealAuditada > 0) {
              getDatosHistoricoInventario(record.key, record.C_Inventario_Cab);
            }
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          block
        />
      ),
      fixed: "right",
    },
  ];

  const columnsReconteo: TableColumnsType<InventarioReconteo> = [
    {
      title: "Producto",
      dataIndex: "DescripcionProducto",
      key: "DescripcionProducto",
      ...getColumnSearchPropsNewVisorReporte2("DescripcionProducto"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Laboratorio",
      dataIndex: "NombreLaboratorio",
      key: "NombreLaboratorio",
      width:"150px",
      filters: [...new Set(filteredDataReconteo.map(({ NombreLaboratorio }) => NombreLaboratorio))].map((NombreLaboratorio) => ({
        text: NombreLaboratorio,
        value: NombreLaboratorio,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.NombreLaboratorio,
      className: "letraPequenaVisor2",
    },

    {
      title: "Fecha registro",
      dataIndex: "Fecha_Reconteo",
      key: "Fecha_Reconteo",
      width:"150px",
      sorter: (a, b) => moment(a.Fecha_Reconteo).unix() - moment(b.Fecha_Reconteo).unix(),
      sortDirections: ["descend", "ascend"],
      className: "letraPequenaVisor2",
    },
    {
      title: "Cant Real",
      dataIndex: "Cant_Real",
      align: "right",
      className: "letraPequenaVisor2",
      width: "100px",
      render: (_, record) => (
        <span style={{ color: record.Cant_Real === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Cant_Real)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Cant real Recontada",
      dataIndex: "CantRealReconteo",
      align: "right",
      className: "letraPequenaVisor2",
      width: "130px",
      render: (_, record) => (
        <span
          style={{
            color: record.CantRealReconteo === 0 ? "lightgray" : "black",
          }}
        >
          {formateaCantidad(record.CantRealReconteo)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Estado",
      width: "100px",
      dataIndex: "Estado",
      key: "Estado",
      render: (value: String) =>
        value === "Cuadrado" ? (
          <Tag color="success">Cuadrado</Tag>
        ) : value === "Faltante" ? (
          <Tag color="red">Faltante</Tag>
        ) : (
          <Tag color="warning">Sobrando</Tag>
        ),

      filters: [...new Set(filteredDataReconteo.map(({ Estado }) => Estado))].map((Estado) => ({
        text: Estado,
        value: Estado,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Estado,
      className: "letraPequenaVisor2",
    },
    {
      title: "Usuario",
      dataIndex: "persona",
      align: "right",
      className: "letraPequenaVisor2",
      width: "150px",
    },
  ];

  const columsDatatableDetalleHistorico: TableColumnsType<DatosInventariosEspecial> = [
    {
      title: "Nombre Auditor",
      dataIndex: "Nombres_Rol",
      key: "Nombres_Rol",
      className: "letraPequenaVisor2",
      filters: [...new Set(datosHistoricos.map(({ Nombres_Rol }) => Nombres_Rol))].map((Laboratorio) => ({
        text: Laboratorio,
        value: Laboratorio,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Nombres_Rol,
      //...getColumnSearchPropsNew("laboratorios"),
    },
    {
      title: "Cajas Auditadas",
      dataIndex: "Cajas_Buenas",
      key: "Cajas_Buenas",
      className: "letraPequenaVisor2",
    },
    {
      title: "Fracciones Auditadas",
      dataIndex: "Fracciones_Buenas",
      key: "Fracciones_Buenas",
      align: "right",
    },
    {
      title: "Cantidad Real Auditada",
      dataIndex: "CantidadRealAuditada",
      key: "CantidadRealAuditada",
      align: "right",
      render: (_, record) => (
        <span
          style={{
            color: record.CantidadRealAuditada === 0 ? "lightgray" : "black",
          }}
        >
          {formateaCantidad(record.CantidadRealAuditada)}
        </span>
      ),
    },
    {
      title: "Fecha escaneo",
      dataIndex: "FechaEscaneo",
      key: "FechaEscaneo",
      className: "letraPequenaVisor2",
    },
  ];
  const handleChangeButton = () => {
    try {
      if (bodega !== undefined && bodega >= 0 && mes) {
        getBodegas(bodega, mes, 1);
        setEstadoTipo(1);
      }
      //
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeButtonTerminar = async () => {
    await Swal.fire({
      title: "Terminar inventario",
      text: "¿Está seguro de terminar este inventario?, si presiona en aceptar se realizarán los respectivos ajustes",
      icon: "question",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonColor: "#8b0505",
    }).then(async (result) => {
      if (result.isConfirmed && bodega !== undefined) {
        await terminarInventario(datos[0].C_Inventario_Cab, bodega);
      }
    });
  };

  const handleChangeTipo = async (tipo: number) => {
    if (bodega !== undefined && bodega >= 0 && mes && tipo === 1) {
      getBodegas(bodega, mes, 1);
      setEstadoTipo(1);
    } else if (bodega !== undefined && bodega >= 0 && mes && tipo === 2) {
      getBodegas(bodega, mes, 2);
      setEstadoTipo(2);
    }
  };
  const handleClickDownloadProductos = () => {
    if (tipo === 1) {
      const datosParaExportar = filteredData.map(({Cod_Estado,Estado_Reconteo,C_Inventario_Cab,Costo_Promedio, ...resto }) => resto);
      exportToExcel(datosParaExportar ?? [], "DetalleInventario");
    } else {
      const datosParaExportar = filteredDataReconteo.map(({Cod_Estado,Estado_Reconteo,C_Inventario_Cab, ...resto }) => resto);
      exportToExcel(datosParaExportar ?? [], "DetalleInventarioReconteo");
    }
  };
  const handleChangeButtonEmpezarReconteo = async () => {
    await Swal.fire({
      title: "Empezar reconteo",
      text: "¿Está seguro de empezar reconteo?",
      icon: "question",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonColor: "#8b0505",
    }).then(async (result) => {
      if (result.isConfirmed && bodega !== undefined) {
        await empezarReconteo(datos[0].C_Inventario_Cab, bodega);
      }
    });
  };

  return {
    columns,
    mes,
    setMes,
    filteredData,
    handleCancelHistorico,
    isModalOpenHistorico,
    datosPeriodos,
    datosTipos,
    setBodega,
    columsDatatableDetalleHistorico,
    datosHistoricos,
    /* handleClickDownloadDataHistorico,
    setRowClassName,*/
    estadoBotonFinalizar,
    estadoBotones,
    bodega,
    handleChangeButton,
    dataEstado,
    handleChangeButtonTerminar,
    handleChangeButtonEmpezarReconteo,
    estadoBotonesFin,
    estadoBotonReconteo,
    handleChangeTipo,
    columnsReconteo,
    tipo,
    filteredDataReconteo,
    handleClickDownloadProductos,
  };
}
