import { useCallback, useEffect, useState } from "react";

import { optionsPOST, url_interna } from "../../config/constants";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";

type ActasReporte = {
  fecha: string;
  bodegaDestino:string;
  bodegaOrigen:string;
  observacion:string;
  total:number;
  nombrePersona:string;
  supervisor:string;
  key: React.Key;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Cargos pendientes" },
];

const openKeys = [breadcrumbs[0].Label];
export function useCargosPendientes() {
  useLayout(
    breadcrumbs,
    openKeys,
    breadcrumbs[0].Label + "/" + breadcrumbs[1].Label
  );

  const { exportToExcel } = useExport();
  const [datos, setDatos] = useState<ActasReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<ActasReporte[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<ActasReporte>();



  const { isError } = useVerifyResponse();

  const getBodegas = useCallback(async () => {
    setDatos([]);
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener_cargos_pendientes",
        optionsPOST(
          JSON.stringify({
            codBodega: "codBodega",
          }),
          {
            "api-token": "apitoken",
          }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: ActasReporte[] = respuesta.data;
        console.log(respuestas);
        if (respuestas.length > 0) {
          setDatos(
            respuestas.map(
              ({ key,  bodegaOrigen,bodegaDestino,fecha, observacion, total,nombrePersona,supervisor }) => ({
                key,  bodegaOrigen,bodegaDestino, fecha,observacion, total,nombrePersona,supervisor 
              })
            )
          );
        } else {
          setDatos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [ isError]);

  useEffect(() => {
    getBodegas();
  }, [getBodegas]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos);
    } else {
      const filtered = datos.filter((item) =>
        item.bodegaDestino.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchText, datos]);
  const handleClickDownloadData = () => {
    const datosParaExportar = filteredData.map(({ key, ...resto }) => resto);
    exportToExcel(datosParaExportar, `Cargos pendientes`);
  };
  const columns: TableColumnsType<ActasReporte> = [
    {
      title: "Farmacia recibe",
      dataIndex: "bodegaDestino",
      key: "bodegaDestino",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("bodegaDestino"),
    },

    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      className: "letraPequenaVisor2",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Farmacia envía",
      dataIndex: "bodegaOrigen",
      key: "bodegaOrigen",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("bodegaOrigen"),
    },
    {
      title: "Usuario",
      dataIndex: "nombrePersona",
      key: "nombrePersona",
      className: "letraPequenaVisor2",
    },
    {
      title: "Observación",
      className: "letraPequenaVisor2",
      dataIndex: "observacion",
      key: "observacion",
    },
    {
      title: "Total",
      dataIndex: "total",
      className: "letraPequenaVisor2",
      key: "total",
      align: "right",
      render: (total) => `$${total}`,
    },
  ];
  return {
    columns,
    filteredData,
    handleClickDownloadData
  };
}
